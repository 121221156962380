import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import LeafMap from "leaflet";
import SitesList from "./SitesList";
import useLeafletMap from "../../../components/LeafLetMap/useLeafletMap";
import { MapConfig, MapIcon } from "../../../ constants";
import { IconOptions } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";

const ViewSitesInfo = () => {
  const [siteData, setSiteData] = useState<any>([]);

  const mapRef = useRef<HTMLDivElement>(null);
  let map = useLeafletMap(mapRef, MapConfig.tileLayer.uri);

  useEffect(() => {
    if (map) {
      if (mapRef.current && siteData.length > 0) {
        const markersLayer = LeafMap.featureGroup();
        siteData?.forEach((data: any) => {
          if (data?.location !== null) {
            const marker = LeafMap.marker(data?.location?.coordinates);
            map?.flyTo(data?.location?.coordinates, map.getZoom());
            marker.setIcon(LeafMap.icon(MapIcon as IconOptions));
            const popup = LeafMap.popup({
              closeButton: false,
              className: "leak-marker",
              offset: [-3, 1],
            }).setContent(
              `<div class="c-tooltip">
                                <table width="100%"  class="vaolum-data-table">
                                    <thead><tr><th colspan='2'>Site Name:- ${data.name}</th></tr></thead>
                                    <tbody>
                                        <tr>
                                            <td>Latitude</td>
                                            <td>${data?.location?.coordinates[0]}</td>
                                        </tr>
                                        <tr>
                                            <td>Longitude</td>
                                            <td>${data?.location?.coordinates[1]}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>`
            );
            marker.bindPopup(popup);
            markersLayer.addLayer(marker);

            marker.on("mouseover", (e: any) => {
              e.target.openPopup();
            });
            marker.on("mouseout", (e: any) => {
              e.target.closePopup();
            });
          }
        });
        map.addLayer(markersLayer);
        map.fitBounds(markersLayer.getBounds(), { maxZoom: 15 });
        const bounds = markersLayer.getBounds();
        map.fitBounds(bounds, { padding: [50, 50], maxZoom: 4 });

        map.invalidateSize();
      }
    }
  }, [map, siteData]);

  return (
    <>
      <Box mt={3}>
        <SitesList setSiteData={setSiteData} />
      </Box>

      <Box className="map-container" style={{ height: "80%" }}>
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: siteData.length > 0 ? "block" : "none",
          }}
        >
          <h4 style={{ color: "white", margin: "10px 40%" }}>Site Location</h4>
          <div
            ref={mapRef}
            id="map"
            style={{ height: "80%", width: "95%" }}
          ></div>
        </Box>
      </Box>
    </>
  );
};

export default ViewSitesInfo;
