import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  FC,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Autocomplete,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Paper,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Error from "../ErrorBox/Error";
import { Country, State, City } from "country-state-city";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import {
  setError,
  useCreateSitesMutation,
  useEditSitesMutation,
  useGetOrganizationByIdQuery,
  useGetSitesByIdQuery,
} from "../../redux-services";
import { useDispatch } from "react-redux";
import { SiteState } from "../../pages/Sites/AddEditSites";
import Maps from "./Maps";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";

interface FormErrors {
  name?: string;
  address?: string;
  postal_code?: string;
  contact_no?: string;
  latitude?: string;
  longitude?: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};
const SiteAddEditModal: FC<{
  open: boolean;
  handleModel: any;
  sitesData?: SiteState;
  isEdit?: boolean;
}> = ({ open, handleModel, sitesData, isEdit }) => {
 
  const [addressData, setAddressData] = useState<any>({
    country: [],
    state: [],
    city: [],
  });

  const [addressCode, setAddressCode] = useState<any>({
    countryCode: "",
    stateCode: "",
  });

  let { id } = useParams();
  const formRef = useRef<HTMLFormElement>(null);
  const telRef = useRef<HTMLInputElement | null>(null);

  const intialState = {
    name: "",
    organization_id: id ?? "",
    address: "",
    latitude: "",
    longitude: "",
    city: "",
    state: "",
    country: "",
    contact_no: "",
    input_contact_no: "",
    iute164_phone: "",
    ccode: "",
    postal_code: "",
    images: [],
    image_url: [],
    urls: [],
    deleted_images: [],
  };

  let dispatch = useDispatch();
  const [formData, setFormData] = useState<SiteState>(intialState);
  const [organizationName, setOrganizationName] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [editSites, editResult] = useEditSitesMutation();
  const [createSites, result] = useCreateSitesMutation();
  const { data: UserData } = useGetOrganizationByIdQuery(id, {
    skip: !id,
  });
  const { data: SitesData } = useGetSitesByIdQuery(id, {
    skip: !isEdit,
  });

  const getAddressFromLatLon = useCallback(async () => {
    try {
      const response: any = await axios.get(
        `https://nominatim.openstreetmap.org/search.php?q=${formData.city}%2C${formData.state}%2C${formData.country}&polygon_geojson=1&format=jsonv2`
      );

      formData.latitude = response?.data[0]?.lat;
      formData.longitude = response?.data[0]?.lon;
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  }, [formData.country, formData.state, formData.city]);

  useEffect(() => {
    if (UserData?.status === 200) {
      let orgData = UserData?.response && UserData?.response[0];
      setOrganizationName(orgData?.organization_name ?? "");
    } else if (SitesData) {
      setOrganizationName(
        SitesData?.response.organization_id?.[0]?.organization_name || ""
      );
    }
  }, [UserData, SitesData]);

  const [defaultCountry, setDefaultCountry] = useState<any>(null);
  const [defaultState, setDefaultState] = useState<any>(null);
  const [defaultCity, setDefaultCity] = useState<any>(null);

  useEffect(() => {
    console.log("addressData.country:", addressData.country);
    const india = addressData.country.find(
      (country: { isoCode: string }) => country.isoCode === "IN"
    );
    if (india) {
      setDefaultCountry(india);
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: india.name,
      }));
    } else {
      console.error("India not found in addressData.country");
    }
  }, [addressData.country]);

  useEffect(() => {
    getAddressFromLatLon();
  }, [formData.country, formData.state, formData.city]);

  useEffect(() => {
    if (defaultCountry !== undefined) {
      setAddressCode({ ...addressCode, countryCode: defaultCountry?.isoCode });
    }
  }, [defaultCountry]);

  useEffect(() => {
    if (defaultState !== undefined) {
      setAddressCode({ ...addressCode, stateCode: defaultState?.isoCode });
    }
  }, [defaultState]);

  useEffect(() => {
    const stateList = State.getStatesOfCountry(addressCode?.countryCode);
    if (stateList.length > 0) {
      setDefaultState(
        stateList.find((state: any) => state.name === formData?.state)
      );
    }
    const cityList = City.getCitiesOfState(
      addressCode.countryCode,
      addressCode.stateCode
    );
    if (cityList.length > 0) {
      setDefaultCity(
        cityList.find((city: any) => city.name === formData?.city)
      );
    }
  }, [addressCode]);

  useEffect(() => {
    setAddressData({
      ...addressData,
      country: Country.getAllCountries() ?? [],
      state: State.getStatesOfCountry(addressCode.countryCode) ?? [],
      city:
        City.getCitiesOfState(addressCode.countryCode, addressCode.stateCode) ??
        [],
    });
  }, [Country, State, City, addressCode]);

  useEffect(() => {
    if (SitesData?.status === 200) {
      let data: any = SitesData?.response;
      setFormData({
        ...formData,
        name: data?.name ?? "",
        address: data?.address ?? "",
        latitude: data?.location?.coordinates
          ? String(data?.location?.coordinates[0])
          : "",
        longitude: data?.location?.coordinates
          ? String(data?.location?.coordinates[1])
          : "",
        city: data?.city ?? "",
        ccode: data?.ccode ?? "",
        iute164_phone: (data?.ccode ?? "") + (data?.contact_no ?? ""),
        input_contact_no: (data?.ccode ?? "") + (data?.contact_no ?? ""),
        contact_no: data?.contact_no ?? "",
        organization_id: data?.organization_id[0]?._id ?? "",
        state: data?.state ?? "",
        country: data?.country ?? "",
        postal_code: data?.postal_code.toString() ?? "",
        images: data?.images?.length
          ? data.images.map((image: any) => image.path)
          : [],
        image_url: data?.encodedUrl?.length ? data.encodedUrl : [],
        urls: data.images?.length
          ? data?.images.map((image: any) => image.fullUrl)
          : [],
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [SitesData]);

  if (editResult.isSuccess && editResult.data.status === 200) {
    handleModel(false);
  }
  if (result.isSuccess && result.data.status === 200) {
    handleModel(false);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    if (formRef?.current?.checkValidity()) {
      let data = new FormData();
      for (const item of formData.images) {
        data.append("images", item);
      }
      if (isEdit === true && id) {
        for (const item of formData.deleted_images) {
          data.append("deleted_images", item);
        }
      }
      data.append("organization_id", formData.organization_id ?? "");
      data.append("name", formData.name.trim());
      data.append("postal_code", formData.postal_code.trim());
      data.append("latitude", formData.latitude);
      data.append("longitude", formData.longitude);
      data.append("address", formData.address.trim());
      data.append("country", formData.country.trim());
      data.append("state", formData.state.trim());
      data.append("city", formData.city.trim());
      data.append("ccode", formData.ccode);
      data.append("contact_no", formData.contact_no.trim());
      data.append("id", isEdit ? id : (null as any));
      data.append("organization_name", organizationName);
      isEdit ? editSites(data) : createSites(data);
    }
  };

  const handleExternalSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };
  const validate = (formData: SiteState) => {
    const errors: Partial<SiteState> = {};
    if (!formData.name.trim()) {
      errors.name = "Site Name is required!";
    }

    if (!formData.address.trim()) {
      errors.address = "Address is required!";
    }
    if (!formData.latitude?.toString().trim()) {
      errors.latitude = "Latitude is required!";
    } else if (
      isNaN(Number(formData.latitude)) ||
      Number(formData.latitude) < -90 ||
      Number(formData.latitude) > 90
    ) {
      errors.latitude = "Latitude must be a number between -90 and 90!";
    }

    if (!formData.longitude?.toString().trim()) {
      errors.longitude = "Longitude is required!";
    } else if (
      isNaN(Number(formData.longitude)) ||
      Number(formData.longitude) < -180 ||
      Number(formData.longitude) > 180
    ) {
      errors.longitude = "Longitude must be a number between -180 and 180!";
    }

    const contactNo = formData.contact_no?.trim();
    if (!contactNo) {
      errors.contact_no = "Contact number is required!";
    } else if (contactNo.length !== 10 || !/^\d{10}$/.test(contactNo)) {
      errors.contact_no = "Contact number must be exactly 10 digits long!";
    } else if (!/^([6789]\d{9})$/.test(contactNo)) {
      errors.contact_no =
        "For Indian numbers, the contact number must start with digits 6, 7, 8, or 9!";
    }

    if (!formData?.postal_code || !formData?.postal_code?.trim()) {
      errors.postal_code = "Postal Code is required!";
    } else if (!/^\d+$/.test(formData?.postal_code?.trim())) {
      errors.postal_code = "Postal Code must contain only digits!";
    } else if (formData?.postal_code?.trim().length !== 6) {
      errors.postal_code = "Postal Code must be exactly 6 digits long!";
    }
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;

    const formattedValue = value
      .split(" ")
      .map((word: string, index: number) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      })
      .join(" ");
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: formattedValue,
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof SiteState],
    }));
  };
  const handleCountryChange = (data: any) => {
    setFormData({
      ...formData,
      country: data !== null ? data.name : "",
      state: "",
      city: "",
    });
    setDefaultCountry(data);
    setDefaultState(null);
    setDefaultCity(null);
    setAddressCode({ ...addressCode, countryCode: data?.isoCode });
  };

  const handleStateChange = (data: any) => {
    setFormData({
      ...formData,
      state: data !== null ? data.name : "",
      city: "",
    });
    setDefaultState(data);
    setDefaultCity(null);
    setAddressCode({ ...addressCode, stateCode: data?.isoCode });
  };

  const handleCityChange = (data: any) => {
    setDefaultCity(data);
    setFormData({ ...formData, city: data !== null ? data.name : "" });
  };

  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData({
      ...formData,
      images: [...formData.images, ...file],
      image_url: [...formData.image_url, ...setFileUrl],
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;
  };
  const onPhoneChange = (value: string, parsedNumber: MuiTelInputInfo) => {
    const telElement = telRef.current;
    const cursorPosition = telElement?.selectionStart ?? 0;

    let contactNo = parsedNumber?.nationalNumber ?? "";
    let iute164Phone = parsedNumber?.numberValue ?? "";
    let ccode = "+" + (parsedNumber?.countryCallingCode ?? "");
    const updatedFormData = {
      ...formData,
      input_contact_no: value,
      contact_no: contactNo,
      iute164_phone: iute164Phone,
      ccode: ccode,
    };

    const contactNoErrors = validate({
      ...formData,
      contact_no: contactNo,
    });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact_no: contactNoErrors.contact_no,
    }));

    setFormData(updatedFormData);
    
    if (telElement && value.length < formData.input_contact_no.length) {
      setTimeout(() => {
          telElement.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };
  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="form-outers karuModal">
          <Typography
            id="transition-modal-title"
            variant="h5"
            className="inner-headings"
            component="h2"
          >
            {isEdit ? "Edit" : "Add"} Site
          </Typography>
          <HighlightOffIcon
            className="closeicon"
            onClick={() => handleModel(false)}
          />

          <Box className="modalBody">
            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
            <form ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    type="text"
                    size="small"
                    autoComplete="name"
                    value={formData.name}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    onChange={handleChange}
                  />
                  <Error current_key="name" />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ color: "white" }}>Address</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="address"
                    label="Address"
                    name="address"
                    type="text"
                    size="small"
                    autoComplete="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  <Error current_key="address" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiTelInput
                    value={formData?.input_contact_no}
                    onChange={onPhoneChange}
                    defaultCountry={"IN"}
                    inputProps={{
                      maxLength: 15,
                      ref: telRef,
                    }}
                    size="small"
                    fullWidth={true}
                    error={!!formErrors.contact_no}
                    helperText={formErrors.contact_no}
                  />
                  <Error current_key="contact_no" />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={addressData.country}
                    size="small"
                    onChange={(event, newValue) => {
                      handleCountryChange(newValue);
                    }}
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#0a243d" }}>
                        {children}
                      </Paper>
                    )}
                    value={defaultCountry}
                    // disableCloseOnSelect={true}
                    className="raju-clas"
                    autoHighlight
                    getOptionLabel={(option: any) => option?.name}
                    renderOption={(props, option: any) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.name} ({option.isoCode}) +{option?.phonecode}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={addressData.state}
                    size="small"
                    onChange={(event, newValue) => {
                      handleStateChange(newValue);
                    }}
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#0a243d" }}>
                        {children}
                      </Paper>
                    )}
                    value={defaultState}
                    autoHighlight
                    getOptionLabel={(option: any) => option?.name}
                    renderOption={(props, option: any) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.name} ({option?.isoCode})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a State"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        value={formData.state}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={addressData.city}
                    size="small"
                    onChange={(event, newValue) => {
                      handleCityChange(newValue);
                    }}
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#0a243d" }}>
                        {children}
                      </Paper>
                    )}
                    value={defaultCity}
                    autoHighlight
                    getOptionLabel={(option: any) => option?.name}
                    renderOption={(props, option: any) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.name} ({option?.stateCode})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a City"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        value={formData.city}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    id="postal_code"
                    label="Postal Code"
                    name="postal_code"
                    type="string"
                    size="small"
                    inputProps={{ maxLength: 6 }}
                    autoComplete="postal_code"
                    value={formData.postal_code}
                    error={!!formErrors.postal_code}
                    helperText={formErrors.postal_code}
                    onChange={handleChange}
                  />
                  <Error current_key="postal_code" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="latitude"
                    label="Latitude"
                    name="latitude"
                    type="number"
                    size="small"
                    autoComplete="latitude"
                    value={formData.latitude}
                    error={!!formErrors.latitude}
                    helperText={formErrors.latitude}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Error current_key="latitude" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="longitude"
                    label="Longitude"
                    name="longitude"
                    type="number"
                    size="small"
                    autoComplete="longitude"
                    value={formData.longitude}
                    error={!!formErrors.longitude}
                    helperText={formErrors.longitude}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Error current_key="longitude" />
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="images"
                      name="images"
                      label="Sites Images"
                      type="file"
                      className="sites_img"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ accept: "image/*", multiple: true }}
                      autoComplete="images"
                      onChange={handleChangeImage}
                    />
                    <Error current_key="images" />
                  </Grid>
                  <Grid item xs={12} className="imageDiv">
                    {formData?.image_url?.length
                      ? formData.image_url.map(
                          (image: string, index: number) => (
                            <div key={index}>
                              <img
                                src={image}
                                alt={`sites-img-${index}`}
                                className="preview-img"
                              />
                              <button
                                onClick={() => handleRemoveImage(index)}
                                title="Remove Image"
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  cursor: "pointer",
                                }}
                                aria-label="Remove Image"
                              >
                                <ClearIcon />
                              </button>
                            </div>
                          )
                        )
                      : ""}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Maps
                    setFormData={setFormData}
                    formData={formData}
                    siteName={sitesData?.name}
                    siteLatLon={sitesData?.location?.coordinates}
                    boreAreaMap={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </form>
          </Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="save-btn"
              style={{ marginRight: "10px" }}
              onClick={handleExternalSubmit}
              disabled={Object.values(formErrors).some((error) => !!error)}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="close-btn"
              onClick={() => handleModel(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};
export default memo(SiteAddEditModal);
