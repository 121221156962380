import React, { FC, memo, useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  Paper,
  Toolbar,
  Typography,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { PageMeta } from "../../../../types/CommonTypes";
import { useParams } from "react-router-dom";
import {
  useEarningExpenditureReportListQuery,
  useEmpAttendanceMonthlyReportListQuery,
  useMachineMonthlyReportListQuery,
} from "../../../../redux-services";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
// import subDays from "date-fns/subDays";
// import startOfWeek from "date-fns/startOfWeek";
// import endOfWeek from "date-fns/endOfWeek";
// import addDays from "date-fns/addDays";
import {
  addDays,
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  endOfMonth,
  addMonths,
} from "date-fns";

// import startOfMonth from "date-fns/startOfMonth";
// import endOfMonth from "date-fns/endOfMonth";
// import addMonths from "date-fns/addMonths";
import { DefaultizedPieValueType } from "@mui/x-charts/models";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import MachineMonthlyReport from "./MachineMonthlyReport";
import EmployeeAttendanceReport from "./EmployeeAttendanceReport";
import EarningExpenditureReport from "./EarnExpense/EarningExpenditureReport";
import * as XLSX from "xlsx";

interface RenderContentProps {
  monthlyReportList: any;
  dateRange: any;
  handleDateChange: (range: any) => void;
  selectChange: (event: SelectChangeEvent<any>) => void;
  EmpAttendanceReportData: any;
  refetchEmpAttendance: () => void;
  MachineReportData: any;
  refetchMachineReport: () => void;
  EarningExpenditureReportData: any;
  refetchEarningExpense: () => void;
  handlereport: any;
  earningPieData: any;
  expensePieData: any;
  tripPiedata: any;
  fuelPiedata: any;
  sizing: any;
}

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
  {
    label: "This year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "All time",
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: "left",
  },
  {
    label: "Last week",
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
      ];
    },
    appearance: "default",
  },
  {
    label: "Next week",
    closeOverlay: false,
    value: (value: any) => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
      ];
    },
    appearance: "default",
  },
];

export const renderContent = ({
  monthlyReportList,
  dateRange,
  handleDateChange,
  selectChange,
  EmpAttendanceReportData,
  refetchEmpAttendance,
  MachineReportData,
  refetchMachineReport,
  EarningExpenditureReportData,
  handlereport,
  refetchEarningExpense,
  earningPieData,
  expensePieData,
  tripPiedata,
  fuelPiedata,
  sizing,
}: RenderContentProps) => (
  <div>
    <Paper className="form-outers">
      <Grid container spacing={0}>
        <Grid
          item
          md={
            ["attendance", "None", "earning_expense"]?.includes(
              monthlyReportList?.option
            )
              ? 12
              : 9
          }
        >
          <Toolbar className="pe-0">
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              component="div"
              className="inner-headings"
            >
              {monthlyReportList?.option === "None" && "Monthly Report Detail"}
              {monthlyReportList?.option === "machine" &&
                "Machine Monthly Report"}
              {monthlyReportList?.option === "attendance" &&
                "Employee Attendance Report"}
              {monthlyReportList?.option === "earning_expense" &&
                "Earning Expenditure Report"}
            </Typography>
            <div className="flex-boxinner worklogs">
              <Typography
                sx={{ flex: "1 1 auto" }}
                className="datepicker-outer"
              >
                <DateRangePicker
                  ranges={predefinedRanges as any}
                  placeholder="Select Date Range"
                  showOneCalendar
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  className="dateselecttype"
                  // defaultValue={[dateRange?.startDate, dateRange?.endDate]}
                />
                <Box sx={{ minWidth: 270, mr: 2 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Select Option *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        monthlyReportList.option !== ""
                          ? monthlyReportList.option
                          : "None"
                      }
                      label="Select Option"
                      required
                      onChange={selectChange}
                    >
                      <MenuItem value={"None"}>None</MenuItem>
                      <MenuItem value={"machine"}>Machinery Report</MenuItem>
                      <MenuItem value={"attendance"}>
                        Employee Attendance Report
                      </MenuItem>
                      <MenuItem value={"earning_expense"}>
                        Earning Expenditure Report
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Typography>
            </div>
            {monthlyReportList?.option !== "None" && (
              <div>
                <Tooltip title="Download report" arrow className="me-1 mt-1">
                  <Button
                    style={{ height: "100%" }}
                    className="secondary-btn"
                    variant="contained"
                    color="primary"
                    onClick={handlereport}
                  >
                    Download Report
                  </Button>
                </Tooltip>
              </div>
            )}
          </Toolbar>
                    {monthlyReportList?.option === "attendance" &&
            monthlyReportList?.option !== "None" ? (
              monthlyReportList?.startDate !== 0 ? (
                EmpAttendanceReportData?.response?.length > 0 ? (
                  <EmployeeAttendanceReport
                    EmpAttendanceReportData={EmpAttendanceReportData}
                    refetchEmpAttendance={refetchEmpAttendance}
                  />
                ) : (
                  <p>No attendance data available</p>
                )
              ) : (
                <p>Please select a date</p>
              )
            ) : null}
              {monthlyReportList?.option === "machine" && 
               monthlyReportList?.option !== "None" ? (
              monthlyReportList?.startDate !== 0 ? (
              MachineReportData?.response?.length > 0 ? (
              <MachineMonthlyReport
                MachineReportData={MachineReportData}
                refetchMachineReport={refetchMachineReport}
              />
            ) : (
              <p>Loading data...</p>
            )
          ) : (
            <p>Please select a date</p>
          )
        ) : null}
            {monthlyReportList?.option === "earning_expense" &&
        monthlyReportList?.option !== "None" ? (
          monthlyReportList?.startDate !== 0 ? (
            EarningExpenditureReportData?.response ? (
              <EarningExpenditureReport
                earning={EarningExpenditureReportData?.response?.earning}
                expense={EarningExpenditureReportData?.response?.expense}
                refetchEarningExpense={refetchEarningExpense}
              />
            ) : (
              <p>Loading data...</p>
            )
          ) : (
            <p>Please select a date</p>
          )
        ) : null}
        </Grid>
        {monthlyReportList?.option === "earning_expense" && (
          <Grid item md={12}>
            {EarningExpenditureReportData?.status === 200 &&
            monthlyReportList?.option !== "None" ? (
              <Box className="pieChart-outer">
                <Typography
                  variant="h5"
                  id="tableTitle"
                  component="div"
                  className="inner-headings"
                >
                  Graphical Reports
                </Typography>
                <Box className="PieChartone border-bottom">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: earningPieData,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.value}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">Earning Chart</Typography>
                </Box>
                <Box className="PieChartone">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: expensePieData,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.value}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">Expense Chart</Typography>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        )}
        {monthlyReportList?.option === "machine" && (
          <Grid item md={MachineReportData?.response?.length === 0 ? 0 : 3}>
            {MachineReportData?.response?.length > 0 &&
            monthlyReportList?.option !== "None" ? (
              <Box className="pieChart-outer">
                <Typography
                  variant="h5"
                  id="tableTitle"
                  component="div"
                  className="inner-headings"
                >
                  Graphical Reports
                </Typography>
                <Box className="PieChartone border-bottom">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: tripPiedata,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.total_measurement ?? 0}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">Machine Running Chart</Typography>
                </Box>
                <Box className="PieChartone">
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        data: fuelPiedata,
                        arcLabel: (params: DefaultizedPieValueType) => {
                          if (params.value === 0) {
                            return "0";
                          } else {
                            return `${params.value}`;
                          }
                        },
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 14,
                      },
                    }}
                    {...sizing}
                  />
                  <Typography variant="body1">
                    Fuel Consumption Chart
                  </Typography>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  </div>
);

const MonthlyReport: FC<{ siteDetail: { id: string; name: string } }> = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
    startDate: 0,
    endDate: 0,
    option: "None",
  };
  const { id } = useParams();
  const [tripPiedata, setTripPieData] = useState([
    { label: "Category 1", value: 25 },
    { label: "Category 2", value: 30 },
    { label: "Category 3", value: 45 },
  ]);
  const [fuelPiedata, setFuelPieData] = useState([
    { label: "Category 1", value: 25 },
    { label: "Category 2", value: 30 },
    { label: "Category 3", value: 45 },
  ]);

  const [earningPieData, setEarningPieData] = useState([
    { label: "Category 1", value: 25 },
  ]);
  const [expensePieData, setExpensePieData] = useState([
    { label: "Category 1", value: 25 },
  ]);

  const sizing = {
    margin: { right: 5 },
    width: 200,
    height: 200,
    legend: { hidden: true },
  };

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });
  const [monthlyReportList, setMonthlyReportList] =
    useState<PageMeta>(initialState);

  const { data: MachineReportData, refetch: refetchMachineReport } =
    useMachineMonthlyReportListQuery(
      {
        startDate: monthlyReportList?.startDate ?? 1719772200000,
        endDate: monthlyReportList?.endDate ?? 1722450599059,
        site_id: id ?? "",
      },
      {
        skip:
          (monthlyReportList?.option !== "machine")|| 
          (monthlyReportList.startDate === 0),
      }
    );



  const generateEarningExpenseReport = () => {
    const workbook = XLSX.utils.book_new();
  
    const totalEarning =
      EarningExpenditureReportData?.status === 200
        ? EarningExpenditureReportData?.response?.earning?.trips_earning
        : "N/A";
  
    const earningHeadline = [
      ["Earning Report"],
      [],
      ["Total Earning", totalEarning],
      [],
      ["Earning Report Detail"],
    ];
  
    const earningDetails = [
      ["Machine Name", "Collection"],
      ...(EarningExpenditureReportData?.response?.earning?.data || []).map((log:any) => [
        log.machine_name,
        log.collection,
      ]),
    ];
  
    const earningSheet = XLSX.utils.aoa_to_sheet(earningHeadline);
    XLSX.utils.sheet_add_aoa(earningSheet, earningDetails, { origin: "A8" });
  
    XLSX.utils.book_append_sheet(workbook, earningSheet, "Earning Report");
  
    const expenditureSheet = XLSX.utils.aoa_to_sheet([[]]);
  
    const totalExpenditure =
      EarningExpenditureReportData?.status === 200
        ? EarningExpenditureReportData?.response?.expense?.total_expense
        : "N/A";
  
    const machineExpenditure =
      EarningExpenditureReportData?.status === 200
        ? EarningExpenditureReportData?.response.expense.machine.machine_expense
        : undefined;
  
    const fuelExpenditure =
      EarningExpenditureReportData?.status === 200
        ? EarningExpenditureReportData?.response?.expense?.fuel.fuel_expense
        : undefined;
  
    const salaryExpenditure =
      EarningExpenditureReportData?.status === 200
        ? EarningExpenditureReportData?.response?.expense?.salary?.salary_expense
        : undefined;
  
    const heading = [
      ["Expenditure Report"],
      [],
      ["Total Expenditure", totalExpenditure],
      [],
      salaryExpenditure !== undefined ? ["Salary Expenditure", salaryExpenditure] : [],
      machineExpenditure !== undefined ? ["Machine Expenditure", machineExpenditure] : [],
      fuelExpenditure !== undefined ? ["Fuel Expenditure", fuelExpenditure] : [],
      [],
    ].filter((row) => row.length > 0);
  
    XLSX.utils.sheet_add_aoa(expenditureSheet, heading, { origin: "A1" });
  
    let currentRow = heading.length + 1;
  
    if (machineExpenditure !== undefined) {
      const machineExpenditureHeading = [["Machine Expenditure Report"]];
      XLSX.utils.sheet_add_aoa(expenditureSheet, machineExpenditureHeading, {
        origin: `A${currentRow}`,
      });
      currentRow += machineExpenditureHeading.length + 1;
  
      const machineExpenditureDetails = [
        [
          "Machine Name",
          "Rent Charges(rupees)",
          "Loan Charges(rupees)",
          "Machine Service Amount(rupees)",
          "Total Machine Expense(rupees)",
        ],
        ...EarningExpenditureReportData?.response.expense.machine.data.map((log:any) => [
          log.machine_name,
          log.rent_charges,
          log.loan_charges,
          log.machine_service_amount,
          log.machine_charges,
        ]),
      ];
  
      XLSX.utils.sheet_add_aoa(expenditureSheet, machineExpenditureDetails, {
        origin: `A${currentRow}`,
      });
      currentRow += machineExpenditureDetails.length + 1;
    }
  
    if (fuelExpenditure !== undefined) {
      const fuelExpenditureHeading = [["Fuel Expenditure Report"]];
      XLSX.utils.sheet_add_aoa(expenditureSheet, fuelExpenditureHeading, {
        origin: `A${currentRow}`,
      });
      currentRow += fuelExpenditureHeading.length + 1;
  
      const fuelExpenditureDetails = [
        ["Fuel Type", "Current Fuel Quantity", "Total Amount(Rupees)"],
        ...EarningExpenditureReportData?.response.expense.fuel.data.map((log:any) => [
          log.fuel_type,
          log.current_quantity,
          log.total_amount,
        ]),
      ];
  
      XLSX.utils.sheet_add_aoa(expenditureSheet, fuelExpenditureDetails, {
        origin: `A${currentRow}`,
      });
      currentRow += fuelExpenditureDetails.length + 1;
    }
  
    if (salaryExpenditure !== undefined) {
      const salaryExpenditureHeading = [["Salary Expenditure Report"]];
      XLSX.utils.sheet_add_aoa(expenditureSheet, salaryExpenditureHeading, {
        origin: `A${currentRow}`,
      });
      currentRow += salaryExpenditureHeading.length + 1;
  
      const salaryExpenditureDetails = [
        ["Employee Name", "Contact", "Salary Amount(Rupees)"],
        ...EarningExpenditureReportData?.response?.expense?.salary?.data
          ?.filter((log:any) => log.salary !== null)
          .map((log:any) => [log.username, log.contact, log.salary]),
      ];
  
      XLSX.utils.sheet_add_aoa(expenditureSheet, salaryExpenditureDetails, {
        origin: `A${currentRow}`,
      });
      currentRow += salaryExpenditureDetails.length + 1;
    }
  
    XLSX.utils.book_append_sheet(workbook, expenditureSheet, "Expenditure Report");
  
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
    const filename = `Earning_Expenditure_Report_${formattedDate}_${formattedTime}.xlsx`;
  
    XLSX.writeFile(workbook, filename);
  };
  const generateAttendanceReport = () => {

    if (!EmpAttendanceReportData?.response || EmpAttendanceReportData.response.length === 0) {
      return;
    }
    const workbook = XLSX.utils.book_new();
  
    const attendanceHeadline = [
      ["Attendance Report"],
      [],
    ];
  
    const attendanceDetails = [
      ["Employee Name", "Job Title","Site Name", "Shift Name", "Date", "Attendance Status"],
      [], 
      ...(EmpAttendanceReportData?.response || []).flatMap((entry: any) =>
        (entry.attendance_status || []).map((status: any) => [
          entry.name,
          entry.job_title,
          entry.site_name,
          status.shift_name,
          status.date,
          status.status,
        ])
      ),
    ];
  
    const attendanceData = [...attendanceHeadline, ...attendanceDetails];
  
    const attendanceSheet = XLSX.utils.aoa_to_sheet(attendanceData);
    XLSX.utils.book_append_sheet(workbook, attendanceSheet, "Attendance Report");
  
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
    const filename = `Attendance_Report_${formattedDate}_${formattedTime}.xlsx`;
  
    XLSX.writeFile(workbook, filename);
  };
  const generateMachineReport = () => {
    const workbook = XLSX.utils.book_new();
    const machineData = [
      ["Machine Report"], 
      [], 
      ["Machine Name", "Total Collection", "Total Fuel Consumption", "Total Measurement"],
      
      ...(MachineReportData?.response || []).map((log:any) => [
        log.machine_name,
        log.total_collection,
        log.total_fuel_consumption,
        log.total_measurement,
      ]),
    ];
  
    const machineSheet = XLSX.utils.aoa_to_sheet(machineData);
  
    XLSX.utils.book_append_sheet(workbook, machineSheet, "Machine Report");
  
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
    const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
    const filename = `Machine_Report_${formattedDate}_${formattedTime}.xlsx`;
  
    XLSX.writeFile(workbook, filename);
  };
 

  
  const handlereport = () => {
    if (!monthlyReportList.startDate || monthlyReportList.startDate === 0) {
      alert("Please select a date before downloading the report.");
      return;
    }
    switch (monthlyReportList?.option) {
      case "earning_expense":
        generateEarningExpenseReport();
        break;
      case "attendance":
        generateAttendanceReport();
        break;
        case "machine": 
        generateMachineReport();
        break;
      
      default:
        console.log("No report type selected");
        break;
    }
  };
  const { data: EmpAttendanceReportData, refetch: refetchEmpAttendance } =
    useEmpAttendanceMonthlyReportListQuery(
      {
        startDate: monthlyReportList?.startDate ?? 1719772200000,
        endDate: monthlyReportList?.endDate ?? 1722450599059,
        site_id: id ?? "",
      },
      {
        skip:
          (monthlyReportList?.option !== "attendance")|| 
          (monthlyReportList.startDate === 0),
      }
    );

  const { data: EarningExpenditureReportData, refetch: refetchEarningExpense } =
    useEarningExpenditureReportListQuery(
      {
        startDate: monthlyReportList?.startDate ?? 1719772200000,
        endDate: monthlyReportList?.endDate ?? 1722450599059,
        site_id: id ?? "",
      },
      {
        skip:
          (monthlyReportList?.option !== "earning_expense") ||
          (monthlyReportList.startDate === 0),
      }
    );
  
  // useEffect(() => {
  //   const today = new Date();
  //   const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  //   const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  //   let start = startOfMonth?.setHours(0, 0, 0, 0);
  //   let end = endOfMonth?.setHours(23, 59, 59, 59);
  //   setMonthlyReportList({
  //     ...monthlyReportList,
  //     startDate: start,
  //     endDate: end,
  //   });
  // }, []);

  useEffect(() => {
    if (MachineReportData?.status === 200) {
      let data = [];
      let fuelData = [];
      const response = MachineReportData?.response || [];
      for (let item of response) {
        if (item?.measurement !== 0) {
          data.push({
            label: item?.machine_name ?? "",
            value: item?.measurement ?? 0,
            total_measurement: item?.total_measurement ?? "",
          });
        }
        if (item?.total_fuel_consumption !== 0) {
          fuelData.push({
            label: item?.machine_name ?? "",
            value: item?.total_fuel_consumption ?? 0,
          });
        }
      }
      if (data.length > 0) {
        setTripPieData(data);
      } else {
        setTripPieData([{ label: "No Record Found", value: 100 }]);
      }

      if (fuelData.length > 0) {
        setFuelPieData(fuelData);
      } else {
        setFuelPieData([{ label: "No Record Found", value: 100 }]);
      }
    }
  }, [MachineReportData]);

  useEffect(() => {
    if (EarningExpenditureReportData?.status === 200) {
      let earningData = [];
      let expenseData = [];
      let item = EarningExpenditureReportData?.response;
      earningData.push({
        label: "Earning Report",
        value: item?.earning?.trips_earning ?? 0,
      });
      expenseData.push(
        {
          label: "Salary Expense",
          value: item?.expense?.salary?.salary_expense ?? 0,
        },
        {
          label: "Machine Expense",
          value: item?.expense?.machine?.machine_expense ?? 0,
        },
        {
          label: "Fuel Expense",
          value: item?.expense?.fuel?.fuel_expense ?? 0,
        }
      );
      if (earningData.length > 0) {
        setEarningPieData(earningData);
      } else {
        setEarningPieData([{ label: "No Record Found", value: 100 }]);
      }

      if (expenseData.length > 0) {
        setExpensePieData(expenseData);
      } else {
        setExpensePieData([{ label: "No Record Found", value: 100 }]);
      }
    }
  }, [EarningExpenditureReportData]);

  const handleDateChange = (date: any) => {
    if (date !== null) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0);
      const start = startDate.getTime();

      const endDate = new Date(date[1]);
      endDate.setHours(23, 59, 59, 999);

      const end = endDate.getTime();
      setMonthlyReportList({
        ...monthlyReportList,
        startDate: start,
        endDate: end,
      });
    } else {
      setMonthlyReportList({
        ...monthlyReportList,
        startDate: "",
        endDate: "",
      });
    }
  };

  const selectChange = (event: any) => {
    setMonthlyReportList({
      ...monthlyReportList,
      option: event.target.value as string,
    });
  };

  return renderContent({
    monthlyReportList,
    dateRange,
    handlereport,
    handleDateChange,
    selectChange,
    EmpAttendanceReportData,
    refetchEmpAttendance,
    MachineReportData,
    refetchMachineReport,
    EarningExpenditureReportData,
    refetchEarningExpense,
    earningPieData,
    expensePieData,
    tripPiedata,
    fuelPiedata,
    sizing,
  });
};

export default memo(MonthlyReport);
