import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Typography,
    Box,
    Tab,
    Tabs,
    FormLabel,
    Button,
    Modal,
    Backdrop,
    Fade,
    Grid,
    Toolbar,
} from "@mui/material";

import { Country } from 'country-state-city';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { useGetDocumentDetailQuery, useGetProfileDetailQuery } from '../../redux-services';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserDetailResponse } from '../../types/CommonTypes';
import ViewUserAttendance from './ViewUserAttendance';
import AddEditAttendance from './AddEditAttendance';
import loadingImage from "../../assests/img/loading-36.gif";
import fileNotFound from "../../assests/img/notfound.png";
import dummyProfileImage from "../../assests/img/dummy-image.jpg";
import EditIcon from '@mui/icons-material/Edit';
import AddEditProfile from '../User/AddEditProfile';
import arrowImage from "../../assests/img/arrow.svg"
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
};

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface CountryListType {
    currency: string;
    flag: string;
    isoCode: string;
    latitude: string;
    longitude: string;
    name: string;
    phonecode: string;
    timezones: any[];
}

const Profile = () => {

    const intialState: UserDetailResponse = {
        _id: "",
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        organization_name: "",
        ccode: "",
        role_name: "",
        contact_no: "",
        role: 0,
        profile_img: "",
        job_title: "",
        user_type: "",
        employee_no: "",
        lastWorkOnMachine: "",
    }

    const { id } = useParams();
    const [value, setValue] = React.useState(0);
    const [countryCodeName, setCountryCodeName] = useState<String>('');
    const [userDetail, setUserDetail] = useState<UserDetailResponse>(intialState);
    const [documentLoad, setDocumentLoad] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { isLoading, data, refetch } = useGetProfileDetailQuery(id, { skip: !id });
    const { isLoading: DocumentLoad, data: DocumentData, refetch: refetchDocuments } = useGetDocumentDetailQuery(id, { skip: !id });
    const [editLogId, setEditLogId] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const userDetails = useSelector((state: RootState) => state.auth.user);
    const { state } = useLocation();

    useEffect(() => {
        setIsButtonDisabled(true);
    }, []);
    useEffect(() => {
        setDocumentLoad(true);
    }, [id])

    const [documents, setDocuments] = useState({
        aadhar_card: '',
        aadhar_type: '',
        pan_card: '',
        pan_type: '',
        bank_detail: '',
        bank_type: '',
        driving_license: "",
        driving_type: "",
    });

    useEffect(() => {
        if (data !== undefined) {
            console.log(data, "Data")
            setUserDetail(data?.response);
        }
    }, [data]);

    useEffect(() => {
        let data = {
            aadhar: '',
            aadhar_type: '',
            pan: '',
            pan_type: '',
            bank: '',
            bank_type: '',
            driving_license: '',
            driving_type: '',
        }
        const imageFileTypes = [
            'png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'tiff', 'svg', 'heic', 'heif', 'jfif'
        ];
        const wordFileTypes = [
            'doc',
            'docx',
            'dot',
            'dotx'
        ];
        const pdfFileTypes = [
            'pdf'
        ];

        if (DocumentData !== undefined) {
            if (DocumentData?.response?.aadhar_card) {
                console.log(DocumentData?.response?.aadhar_card, "doc data")
                data.aadhar = DocumentData?.response?.aadhar_card;
                const extension: any = DocumentData?.response?.aadhar_card.split('.').pop().toLowerCase();
                let type = extension?.split('?')?.length && extension?.split('?')[0];
                if (imageFileTypes.includes(type)) {
                    data.aadhar_type = 'image';
                }
                if (wordFileTypes.includes(type)) {
                    data.aadhar_type = 'word';
                }
                if (pdfFileTypes.includes(type)) {
                    const blob = base64toBlob(DocumentData?.response?.aadhar_embedded);
                    let url = URL.createObjectURL(blob);
                    data.aadhar = url;
                    data.aadhar_type = 'pdf';
                }
            }
            if (DocumentData?.response?.pan_card) {
                data.pan = DocumentData?.response?.pan_card;
                const extension: any = DocumentData?.response?.pan_card.split('.').pop().toLowerCase();
                let type = extension?.split('?')?.length && extension?.split('?')[0];
                if (imageFileTypes.includes(type)) {
                    data.pan_type = 'image';
                }
                if (wordFileTypes.includes(type)) {
                    data.pan_type = 'word';
                }
                if (pdfFileTypes.includes(type)) {
                    const blob = base64toBlob(DocumentData?.response?.pan_embedded);
                    let url = URL.createObjectURL(blob);
                    data.pan = url;
                    data.pan_type = 'pdf';
                }
            }
            if (DocumentData?.response?.bank_detail) {
                data.bank = DocumentData?.response?.bank_detail;
                const extension: any = DocumentData?.response?.bank_detail.split('.').pop().toLowerCase();
                let type = extension?.split('?')?.length && extension?.split('?')[0];
                if (imageFileTypes.includes(type)) {
                    data.bank_type = 'image';
                }
                if (wordFileTypes.includes(type)) {
                    data.bank_type = 'word';
                }
                if (pdfFileTypes.includes(type)) {
                    const blob = base64toBlob(DocumentData?.response?.bank_embedded);
                    let url = URL.createObjectURL(blob);
                    data.bank = url;
                    data.bank_type = 'pdf';
                }
            }
            if (DocumentData?.response?.driving_license) {
                data.driving_license = DocumentData?.response?.driving_license;
                const extension: any = DocumentData?.response?.driving_license.split('.').pop().toLowerCase();
                let type = extension?.split('?')?.length && extension?.split('?')[0];
                if (imageFileTypes.includes(type)) {
                    data.driving_type = 'image';
                }
                if (wordFileTypes.includes(type)) {
                    data.driving_type = 'word';
                }
                if (pdfFileTypes.includes(type)) {
                    const blob = base64toBlob(DocumentData?.response?.driving_embedded);
                    let url = URL.createObjectURL(blob);
                    data.driving_license = url;
                    data.driving_type = 'pdf';
                }
            }
            setDocuments({
                ...documents,
                aadhar_card: data.aadhar,
                aadhar_type: data.aadhar_type,
                pan_card: data.pan,
                pan_type: data.pan_type,
                bank_detail: data.bank,
                bank_type: data.bank_type,
                driving_license: data.driving_license,
                driving_type: data.driving_type
            });
            setDocumentLoad(false);
        }
    }, [DocumentData])


    useEffect(() => {
        let getAllCountries = Country.getAllCountries();
        if (userDetail?.ccode !== '') {
            const countryCode = userDetail?.ccode?.replace("+", '');
            let ccode = getAllCountries.find((country: CountryListType | any) => country?.phonecode === countryCode);
            if (ccode?.isoCode !== undefined) {
                setCountryCodeName(ccode?.isoCode);
            }
            if ((userDetail?.organization_name === null)) {
                setValue(1);
            } else {
                if ((userDetail?.user_type === "User")) {
                    setValue(1);
                } else {
                    setValue(0);
                }
            }
        }
    }, [Country, userDetail])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const base64toBlob = useCallback((data: string) => {
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    }, []);

    const ViewDocumentUI = useMemo(() => {
        return (
            <>
                {documentLoad ?
                    (<Box className={documentLoad ? 'document-loader' : ''}>
                        <img src={loadingImage} alt='loader' />
                    </Box>) :
                    <Grid container spacing={3} className='user-document'>
                        {documents.aadhar_card !== '' ?
                            <Grid item xs={12} md={3} className='userDocInner'>
                                {documents.aadhar_type === 'image' ?
                                    <>
                                        <img src={documents.aadhar_card} width="400" height="400" alt={"Aadhar Card Not Found"} />
                                        <Button href={documents.aadhar_card}>
                                            Download
                                        </Button>
                                    </>
                                    :
                                    <iframe src={documents.aadhar_card} width="400" height="400" title="Aadhar Card Document" />
                                }
                                <div className='document-action'>
                                    <span className='doc-name'>Aadhar Card</span>
                                </div>
                            </Grid>
                            : <Grid item xs={12} md={3} className='userDocInner'>
                                <img src={fileNotFound} alt='not-found' />
                                <div>
                                    <span className='doc-name'>Aadhar Card</span>
                                </div>
                            </Grid>
                        }
                        {documents.pan_card !== '' ?
                            <Grid item xs={12} md={3} className='userDocInner'>
                                {documents.pan_type === 'image' ?
                                    <>
                                        <img src={documents.pan_card} width="400" height="400" alt={"Pan Card Not Found"} />
                                        <Button href={documents.pan_card}>
                                            Download
                                        </Button>
                                    </>
                                    :
                                    <iframe src={documents.pan_card} width="400" height="400" title="Pan Card Document" />
                                }
                                <div className='document-action'>
                                    <span className='doc-name'>Pan Card</span>
                                </div>
                            </Grid>
                            : <Grid item xs={12} md={3} className='userDocInner'>
                                <img src={fileNotFound} alt='not-found' />
                                <div>
                                    <span className='doc-name'>Pan Card</span>
                                </div>
                            </Grid>
                        }
                        {documents.bank_detail !== '' ?
                            <Grid item xs={12} md={3} className='userDocInner'>
                                {documents.bank_type === 'image' ?
                                    <>
                                        <img src={documents.bank_detail} width="400" height="400" alt={"Bank Detail Not Found"} />
                                        <Button href={documents.bank_detail}>
                                            Download
                                        </Button>
                                    </>
                                    :
                                    <iframe src={documents.bank_detail} width="400" height="400" title="Bank Detail Document" />
                                }
                                <div className='document-action'>
                                    <span className='doc-name'>Bank Details</span>
                                </div>
                            </Grid>
                            : <Grid item xs={12} md={3} className='userDocInner'>
                                <img src={fileNotFound} alt='not-found' />
                                <div>
                                    <span className='doc-name'>Bank Detail</span>
                                </div>
                            </Grid>
                        }
                        {documents.driving_license !== '' ?
                            <Grid item xs={12} md={3} className='userDocInner'>
                                {documents.driving_type === 'image' ?
                                    <>
                                        <img src={documents.driving_license} width="400" height="400" alt={"Driving License Not Found"} />
                                        <Button href={documents.driving_license}>
                                            Download
                                        </Button>
                                    </>
                                    :
                                    <iframe src={documents.driving_license} width="400" height="400" title="Driving License Document" />
                                }
                                <div className='document-action'>
                                    <span className='doc-name'>Driving License Details</span>
                                </div>
                            </Grid>
                            : <Grid item xs={12} md={3} className='userDocInner'>
                                <img src={fileNotFound} alt='not-found' />
                                <div>
                                    <span className='doc-name'>Driving License Detail</span>
                                </div>
                            </Grid>
                        }
                    </Grid>
                }
            </>
        )
    }, [documents, DocumentLoad, documentLoad]);

    const AttendanceUI = useMemo(() => {
        let UserData = {
            "_id": userDetail._id,
            "displayName": userDetail?.firstname + ` ${userDetail?.lastname} (${userDetail?.ccode + '-' + userDetail?.contact_no})`,
            "machine_id": userDetail?.lastWorkOnMachine ?? '',
        };
        return (
            <AddEditAttendance setOpen={setOpen} id={editLogId} userInfo={UserData} />
        )
    }, [editLogId, userDetail]);

    const handleModel = useCallback((isOpen: boolean) => {
        setModalOpen(isOpen);
        refetch();
        refetchDocuments();
    }, []);
    const breadcrumbs = [
        ...(userDetails?.role_type === 0 ? [{ name: 'Organizations', to: '/admin/organization' }] : []),
        { name: userDetail?.username ?? "", to: `/admin/profile/${id}` }
    ];

    return (
        <>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
            {isLoading ?

                (<Box className={isLoading ? 'doc-loader' : ''}>
                    <img src={loadingImage} alt='loader' />
                </Box>) :
                (<Box>
                    <Box className="employee-profile-container">
                        <Box className="employee-profile-header">
                            <img src={userDetail?.profile_img !== '' ? userDetail?.profile_img : dummyProfileImage} alt="#" />
                        </Box>
                        <Box className="flex-fill">
                            <Box className="flex-card">
                                <Box className="flex-card-body">
                                    <Box className="info-outer" style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Typography variant="h4">{userDetail?.username} <span className="badge-success">{countryCodeName}</span></Typography>
                                        {isButtonDisabled ? null : (
                                            <Button onClick={() => handleModel(true)}><EditIcon /></Button>)}
                                    </Box>
                                    <Box className="user-info">
                                        <Box className=""><FmdGoodOutlinedIcon />{userDetail?.organization_name ?? 'Super Admin Organization'}</Box>
                                        {userDetail?.user_type === 'User' && <Box className=""><MailOutlineOutlinedIcon />{userDetail?.email}</Box>}
                                        <Box className=""><LocalPhoneOutlinedIcon />{userDetail?.ccode + ' ' + userDetail?.contact_no}</Box>
                                        <Box className=""><BadgeOutlinedIcon />{userDetail?.role_name}</Box>
                                    </Box>
                                    <Box className="employee-info">
                                        <Box className="employee-info-inner">
                                            <Box className="data-block">
                                                <FormLabel htmlFor="">JOB TITLE</FormLabel>
                                                <Typography variant="subtitle1">{userDetail?.job_title}</Typography>
                                            </Box>
                                            <Box className="data-block">
                                                <FormLabel htmlFor="">User Type</FormLabel>
                                                <Typography variant="subtitle1">{userDetail?.user_type}</Typography>
                                            </Box>
                                            <Box className="data-block">
                                                <FormLabel htmlFor="">EMP NO</FormLabel>
                                                <Typography variant="subtitle1">{userDetail?.employee_no}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {isModalOpen && (

                            <AddEditProfile
                                open={isModalOpen}
                                handleModel={handleModel}
                                isEdit={true}
                                userType={userDetail?.user_type}
                            />
                        )}
                    </Box>
                    <Box>
                        <Box className="mytabs">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {((userDetail?.organization_name !== null) && (userDetail?.user_type !== "User")) ?
                                    <Tab label="Attendance Log" value={0} />
                                    : ''}
                                <Tab label="Document Detail" value={1} />
                            </Tabs>
                        </Box>
                        {(userDetail && (userDetail?.organization_name !== null && (userDetail?.user_type !== "User"))) ?
                            <CustomTabPanel value={value} index={0}>
                                <ViewUserAttendance id={id} setOpen={setOpen} setEditLogId={setEditLogId} />
                            </CustomTabPanel>
                            : ''}
                        <CustomTabPanel value={value} index={1}>
                            {ViewDocumentUI}
                        </CustomTabPanel>

                    </Box>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style} className='form-outers karuModal'>
                                {AttendanceUI}
                            </Box>
                        </Fade>
                    </Modal>
                </Box>)
            }
        </>
    )
}

export default Profile;