import { FC, useEffect, useRef, useState, useCallback } from "react";
import LeafMap, { bounds, IconOptions, LeafletEvent } from "leaflet";
import useLeafletMap from "../LeafLetMap/useLeafletMap";
import { MapConfig, MapIcon } from "../../ constants";

export interface LatLng {
  latitude: number;
  longitude: number;
}

export interface CustomLeafletEvent {
  type: string;
  popup: any;
  target: any;
  sourceTarget: any;
  propagatedFrom: any;
  latlng: any;
  layer: any;
}

const Maps: FC<any> = ({ setFormData, formData, siteName, setShowDefaultLatLon, boreAreaMap }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useState<LeafMap.Marker | null>(null);
  let map = useLeafletMap(mapRef, MapConfig.tileLayer.uri);

  const clearAllMarkers = useCallback(() => {
    map?.eachLayer((layer) => {
      if (layer instanceof LeafMap.Marker) {
        map?.removeLayer(layer);
      }
    });
    setMarker(null);
  },[map]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMarkerDrag = (event: LeafletEvent) => {
    const newLatLng = event.target.getLatLng();
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      latitude: newLatLng.lat.toString(),
      longitude: newLatLng.lng.toString(),
    }));
  };


  const addOrUpdateMarker = useCallback((
    position: { lat: number; lng: number },
    popupContent: string
  ) => {
    if (marker) {
      marker.setLatLng(position);
      marker.setPopupContent(popupContent);

      map?.setView(position, 9, { animate: true }); 
    } else {
      clearAllMarkers()
      const newMarker = LeafMap.marker(position, { draggable: true });
      console.log(newMarker);
      newMarker.setIcon(LeafMap.icon(MapIcon as IconOptions));
      newMarker.setPopupContent(popupContent).openPopup();
      newMarker.setLatLng(position);
      console.log(newMarker, position);
      newMarker.on('drag', handleMarkerDrag);

      map?.invalidateSize();
      map?.addLayer(newMarker);
      setMarker(newMarker);
  
      map?.flyTo(position, 13);
    }
  },[marker, map, clearAllMarkers, handleMarkerDrag]);
  
  useEffect(() => {
    if (map) {
      map.on("click", (event: CustomLeafletEvent) => {
        const clickedLat = event.latlng.lat;
        const clickedLon = event.latlng.lng;

        if(boreAreaMap){
          setShowDefaultLatLon(false);
        }
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          latitude: clickedLat,
          longitude: clickedLon,
        }));

        const siteInfoRows = getSiteInfoRows(formData, clickedLat, clickedLon);
        const popupContent = createPopupContent(siteInfoRows);
        addOrUpdateMarker(
          { lat: clickedLat, lng: clickedLon },
          popupContent
        );
      });
    }

    return () => {
      if (map) {
        map.off("click");
      }
    };
  }, [map, formData, setFormData, addOrUpdateMarker]);

  useEffect(() => {
    if (formData?.latitude && formData?.longitude && map) {
      const lat = parseFloat(formData.latitude);
      const lng = parseFloat(formData.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const newPosition = { lat, lng };
        const siteInfoRows = getSiteInfoRows(formData, lat, lng);
        const popupContent = createPopupContent(siteInfoRows);

        addOrUpdateMarker(newPosition, popupContent);

        if (map) {
          map.setView(newPosition, 9, { animate: false });
        }
      }
    } else {
      clearAllMarkers();
    }
  }, [addOrUpdateMarker, clearAllMarkers, formData, formData.latitude, formData.longitude, map]);

  const getSiteInfoRows = (formData: any, lat: number, lng: number) => {
    if (formData.road_site) {
      return `
        <tr><td>Bore Name:</td><td>${formData.name}</td></tr>
        <tr><td>Lat:</td><td>${lat}</td></tr>
        <tr><td>Lon:</td><td>${lng}</td></tr>`;
    }
    return `
      <tr><td>Site Name:</td><td>${formData.name}</td></tr>
      <tr><td>Lat:</td><td>${lat}</td></tr>
      <tr><td>Lon:</td><td>${lng}</td></tr>`;
  };

  const createPopupContent = (siteInfoRows: string) => {
    return `
      <div class="c-tooltip">
        <table width="100%" class="vaolum-data-table">
          <thead><tr><th colspan='2'>Detail</th></tr></thead>
          <tbody>${siteInfoRows}</tbody>
        </table>
      </div>`;
  };

  return (
    <div
      ref={mapRef}
      id="map"
      style={{
        height: "400px",
        display: formData?.latitude && formData?.longitude ? "block" : "none",
      }}
    ></div>
  );
};

export default Maps;
