import { createApi } from "@reduxjs/toolkit/query/react";
import {  PageMeta, PaginatedData, Response } from "../../types/CommonTypes";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const MachineTypeApi = createApi({
  reducerPath: "machineTypeApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["machineTypeTag"],

  endpoints: (builder) => ({
    machineTypeList: builder.query<Response<PaginatedData<any>>, PageMeta | void>({
      query: (pageData: PageMeta) => ({
        url: `/machine_type/machine_types`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          pagination: pageData.pagination,
        },
      }),
      providesTags: ["machineTypeTag"],
    }),

    getMachineByOrgId: builder.query<Response<any>, string>({
      query: (id: string) => ({
        url: `/machine_type/${id}`,
        method: "GET",
      }),
      providesTags: ["machineTypeTag"],
    }),

    createMachineType: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/machine_type/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["machineTypeTag"],
    }),

    deleteMachineType: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/machine_type/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["machineTypeTag"],
    }),

    editMachineType: builder.mutation<Response<any>, { id: string; data: any }>(
      {
        query: ({ id, data }) => ({
          url: `/machine_type/edit/${id}`,
          method: "PUT",
          body: data,
        }),
        invalidatesTags: ["machineTypeTag"],
      }
    ),
  }),
});

export const {
  useCreateMachineTypeMutation,
  useMachineTypeListQuery,
  useGetMachineByOrgIdQuery,
  useDeleteMachineTypeMutation,
  useEditMachineTypeMutation,
} = MachineTypeApi;
