import React, { useEffect, useState } from 'react';
import { ColumnsType, Order, PageMeta } from '../../../types/CommonTypes';
import { useGetUserDetailQuery,useGetMachineInfoByIdQuery, useGetVehicleServiceHistoryQuery, useMachineInfoListQuery } from '../../../redux-services';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Checkbox, ListItemText, Typography, Paper, Toolbar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import { TableBodyElement } from '../../../components/TableBody/TableBodyElement';
import arrowImage from "../../../assests/img/arrow.svg";
import { Link } from "react-router-dom";


const tableColumns: ColumnsType[] = [
    { id: "", sort: true, label: "" },
    { id: "reg_no", sort: true, label: "Reg. No." },
    { id: "vin_no", sort: true, label: "Vin. No." },
    { id: "dealer_name", sort: true, label: "Dealer Name" },
    { id: "service_request_no", sort: true, label: "Service Request No" },
    { id: "service_type", sort: true, label: "Service Type" },
    { id: "job_card_no", sort: true, label: "Job Card No" },
    { id: "jc_open_date", sort: true, label: "JC Open Date" },
    { id: "jc_close_date", sort: true, label: "JC Close Date" },
    { id: "invoice_date", sort: true, label: "Invoice Date" },
    { id: "billed_to_customer", sort: true, label: "Billed to the customer" },
    { id: "odometer", sort: true, label: "Odometer" },
    { id: "invoice_amount", sort: true, label: "Invoice Amount" },
];

const useFetchServiceHistory = (params: {
    limit: number,
    page: number,
    order: Order,
    orderBy: string,
    searchText: string,
    selectedYears: string[],
    orgId: string | undefined,
    numberPlate: string | undefined,
}) => {
    const { limit, page, order, orderBy, searchText, selectedYears, orgId, numberPlate } = params;

    const { isSuccess, isLoading, data: serviceHistory } = useGetVehicleServiceHistoryQuery({
        limit: limit,
        page: page,
        order: order,
        order_by: orderBy,
        searchText: searchText,
        year: selectedYears,
        org_id: orgId ?? "",
        number_plate: numberPlate ?? ""
    });

    const serviceResponse: any = serviceHistory?.response;
    return {
        isSuccess,
        isLoading,
        serviceResponse,
    };
};

const MachineServiceHistory = () => {
    const initialState: PageMeta = {
        page: 1,
        per_page: 20,
        searchText: "",
        order: (process.env.REACT_APP_ORDER as Order) || 'desc',
        order_by: process.env.REACT_APP_ORDER_BY || 'createdAt',
    };

    const [machineInfoList, setMachineInfoList] = useState<PageMeta>(initialState);
    const [serviceHistoryList, setServiceHistoryList] = useState<any>({ page: 1, limit: 10 });
    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedYears, setSelectedYears] = useState<string[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [numberPlate, setNumberPlate] = useState<string | undefined>(undefined);
    const [apiCalled, setApiCalled] = useState(false); 
    const [MachineName, setMachineName] = useState<string>('');

    const years = ['2021', '2022', '2023', '2024'];

    const { data: machineData } = useMachineInfoListQuery({
        per_page: machineInfoList.per_page ?? 20,
        page: machineInfoList.page ?? initialState.page,
        order: machineInfoList.order ?? initialState.order,
        order_by: machineInfoList.order_by ?? initialState.order_by,
        searchText: machineInfoList.searchText ?? initialState.searchText,
    });

    useEffect(() => {
        setCurrentUrl(window.location.href);
    }, []);

    const machineId = currentUrl.match(/\/([^\/]+)\/servicehistory$/)?.[1];

    const machine_data: any = machineData?.response?.data;

    useEffect(() => {
        if (machine_data && machineId) {
            const reqMchnDetail = machine_data?.find((mchnInfo: any) => mchnInfo?._id === machineId);
            setNumberPlate(reqMchnDetail?.number_plate);
        }
    }, [machine_data, machineId]);
    const { data: getData } = useGetMachineInfoByIdQuery(machineId, {
        skip: !machineId,
    });
    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response ;
            const machineType = data?.machine_type ?? '';
            const numberPlate = data?.number_plate ?? '';
            if (numberPlate) {
                const lastFourDigits = numberPlate.slice(-4);
                setMachineName(`${machineType}-${lastFourDigits}`);
            } else {
                setMachineName(machineType);
            }
        }
    }, [getData]);

    const toggleCheckbox = (value: string) => {
        setSelectedYears((prevSelectedYears) =>
            prevSelectedYears.includes(value)
                ? prevSelectedYears.filter((year) => year !== value)
                : [...prevSelectedYears, value]
        );
        setServiceHistoryList((prevState: any) => ({
            ...prevState,
            page: 1,
        }));
    };

    const user_data = useGetUserDetailQuery();
    const orgId = user_data?.data?.response?.organization_id;
    const organization_id = localStorage?.getItem('orgId');

    useEffect(() => {
        if (numberPlate !== undefined && !apiCalled) {
            setApiCalled(true);
        }
    }, [numberPlate, apiCalled]);

    const {  serviceResponse } = useFetchServiceHistory({
        limit: 10,
        page: serviceHistoryList.page ?? 1,
        order: serviceHistoryList.order ?? initialState.order,
        orderBy: serviceHistoryList.order_by ?? initialState.order_by,
        searchText: serviceHistoryList.searchText ?? initialState.searchText,
        selectedYears: selectedYears,
        orgId: organization_id ?? orgId, 
        numberPlate: numberPlate
    });

    const handleChangePage = (event: unknown, newPage: number) => {
        setServiceHistoryList((prevState: any) => ({
            ...prevState,
            page: newPage + 1,
        }));
    };
    const breadcrumbs = [
        { name: 'Machinery Lists', to: `/admin/machine` },
        { name: `Service History(${MachineName})`, to: `/admin/site/machine/${machineId}/servicehistory` },
    ];

    return (
        <div>
            {numberPlate !== undefined && apiCalled && (
                <Paper className="form-outers">
                       <Toolbar>
                    <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                        {breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                                {index === breadcrumbs.length - 1 ? (
                                    <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                                ) : (
                                    <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </Toolbar>
                    <Toolbar>
                        <Typography
                            sx={{ flex: '1 1 40%' }}
                            variant="h5"
                            id="tableTitle"
                            className="inner-headings"
                            component="div"
                        >
                            Vehicle Service History
                        </Typography>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ minWidth: 220 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="year-label">Select Years ({selectedYears.length})</InputLabel>
                                    <Select
                                        labelId="year-label"
                                        id="year-select"
                                        label={`Select Years ${selectedYears.length}}`}
                                        multiple
                                        open={isDropdownOpen}
                                        value={selectedYears}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            if (typeof value === 'string') {
                                                toggleCheckbox(value);
                                            } else if (Array.isArray(value)) {
                                                value.forEach((val) => toggleCheckbox(val));
                                            }
                                            setIsDropdownOpen(false);
                                        }}
                                        onClose={() => setIsDropdownOpen(false)}
                                        onOpen={() => setIsDropdownOpen(true)}
                                        renderValue={(selected) => (selected as string[]).join(', ')}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 300,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {years.length > 0 ? (
                                            years.map((year: string) => (
                                                <MenuItem key={year} value={year}>
                                                    <Checkbox checked={selectedYears.includes(year)} />
                                                    <ListItemText primary={year} />
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No Years Available</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Toolbar>
                    <TableContainer component={Paper} className="form-outers">
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                            className="table-outers"
                        >
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell key={column.id}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableBodyElement
                                    selectedColumns={[
                                        "registration_number",
                                        "vin",
                                        "dealer_name",
                                        "service_request_no",
                                        "service_type",
                                        "job_card_no",
                                        "jc_open_date",
                                        "jc_close_date",
                                        "invoice_date",
                                        "billed_to_customer",
                                        "odometer",
                                        "invoice_amount"
                                    ]}
                                    setData={serviceResponse?.result ? serviceResponse?.result : []}
                                />
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={serviceResponse?.total_count ?? 0}
                            page={serviceHistoryList.page - 1}
                            onPageChange={handleChangePage}
                            rowsPerPageOptions={[10, 12]}
                            rowsPerPage={10}
                            labelRowsPerPage={<span className="pagination-label">Items per page: 10</span>}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                            SelectProps={{
                                native: true,
                                inputProps: { 'aria-label': 'rows per page' },
                                style: { display: 'none' }
                            }}
                            classes={{ spacer: 'pagination-container' }}
                        />
                    </TableContainer>
                </Paper>
            )}
        </div>
    );
};

export default MachineServiceHistory;
