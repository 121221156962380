import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import arrowImage from "../../../assests/img/arrow.svg"
import MachineDetail from './MachineDetail';
import MachineFuelEntries from '../FuelEntry';
import MachineWorkLogs from '../Machine Work Logs';
import MachineServiceRecords from '../Machine Service Records';
import MachineDocument from '../Machine Documents';
import { useGetMachineInfoByIdQuery } from '../../../redux-services';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

let CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`organization-tabpanel-${index}`}
            aria-labelledby={`organization-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

let a11yProps = (index: number) => {
    return {
        id: `organization-tab-${index}`,
        'aria-controls': `organization-tabpanel-${index}`,
    };
};

const ViewMachineInfo = () => {

    const { id } = useParams<string>();
    const [organizationName, setOrganizationName] = useState<string>('');
    const [siteName, setSiteName] = useState<string>('');
    const [orgId, setOrgId] = useState<string>('');
    const [siteId, setSiteId] = useState<string>('');
    const [machineName, setMachineName] = useState<string>('');
    const userDetail = useSelector((state: RootState) => state.auth.user);


    const [value, setValue] = React.useState<number>(
        localStorage.getItem('selectedTabMachineryDetails') ? parseInt(localStorage.getItem('selectedTabMachineryDetails') || '0', 10) : 0
    );

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        localStorage.setItem('selectedTabMachineryDetails', newValue.toString())
    }, []);
    const { data: getData } = useGetMachineInfoByIdQuery(id, {
        skip: !id,
    });
    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response ;
            console.log("orgnaemme",data);
            setOrganizationName(data?.organization_name ?? '');
            setOrgId(data?.machinetypeData?.organization_id ?? "");
            setSiteName(data?.siteData[0].name ?? "");
            setSiteId(data?.siteData[0]._id ?? "")
            const machineType = data?.machine_type ?? '';
            const numberPlate = data?.number_plate ?? '';
            if (numberPlate) {
                const lastFourDigits = numberPlate.slice(-4);
                setMachineName(`${machineType}-${lastFourDigits}`);
            } else {
                setMachineName(machineType);
            }
        }
    }, [getData]);
    const breadcrumbs = [
        ...(userDetail?.role_type === 0 ? [{ name: 'Organizations', to: '/admin/organization' }] : []),
        { name: organizationName, to: `/admin/organization/${orgId}` },
        ...(siteName ? [{ name: siteName, to: `/admin/organization/sites/${siteId}` }] : []),
        { name: machineName, to: `admin/site/${siteId}/machine/${id}` },
    ];


    return (
        <Box sx={{ flexGrow: 1 }} className='mt-3'>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Machinery Detail" {...a11yProps(0)} />
                    <Tab label="Machinery Documents" {...a11yProps(1)} />
                    <Tab label="Machinery Logs" {...a11yProps(2)} />
                    <Tab label="Machinery Service" {...a11yProps(3)} />
                    <Tab label="Fuel Entries" {...a11yProps(4)} />
                    {/* <Tab label="Accessories" {...a11yProps(5)} />
                    <Tab label="Toll Expense" {...a11yProps(6)} /> */}
                    {/* <Tab label="Air Pressure Check List" {...a11yProps(6)} /> */}
                    {/* <Tab label="Breakdown List" {...a11yProps(7)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <MachineDetail paramId={id as string} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <MachineDocument />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Box>
                    <MachineWorkLogs />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Box>
                    <MachineServiceRecords />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <Box>
                    <MachineFuelEntries />
                </Box>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={5}>
                <Box>
                    <MachineAccessories />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
                <Box>
                    <TollExpense />
                </Box>
            </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={6}>
                <Box>
                    <AirPressureCheck />
                </Box>
            </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={7}>
                <Box>
                    <MachineBreakDownInfo />
                </Box>
            </CustomTabPanel> */}
        </Box>
    )
}

export default ViewMachineInfo