import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Box,
  Fade,
  Modal,
  Backdrop,
  Divider,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setError,
  useGetMachineInfoByIdQuery,
  useGetShiftDetailQuery,
  useGetSitesByIdQuery,
  useGetUserDataDetailQuery,
  useGetUserDetailQuery,
  useCreateMachineServiceMutation,
  useEditMachineServiceMutation,
  useGetMachineServiceByIdQuery,
} from "../../../redux-services";
import Error from "../../../components/ErrorBox/Error";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { ServiceType } from "../../../ constants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export interface UserState {
  organization_id: any;
  machine_id: any;
  site_id: any;
  service_type: any;
  service_to_time: any;
  operator_id: any;
  shift_id: any;
  amount: any;
  machine_type: any;
  number_plate: any;
  service_datetime: string | Record<string, any>;
  description?: string;
  images: any[];
  current_reading: string;
  image_url: any[];
  urls?: any[];
  deleted_images?: any;
}
interface FormErrors {
  current_reading?: any;
  amount?: any;
  from_time?: any;
}
const AddEditService: React.FC<{
  id: string;
  showModal: boolean;
  setShowModal: any;
  setMachineEntryEditId: any;
  paramId: any;
  siteId: any;
  mchnData: any;
}> = ({
  id,
  mchnData,
  showModal,
  setShowModal,
  setMachineEntryEditId,
  paramId,
  siteId,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const { id: machineId } = useParams();
  let initialState: UserState;
  const site_id: any = siteId;
  const queryParams = new URLSearchParams();
  queryParams.set("siteId", siteId);
  const [siteDetail, setSiteDetail] = useState({
    id: "",
    name: "",
  });

  const { data: getSiteData } = useGetSitesByIdQuery(site_id, {
    skip: !site_id,
  });
  const response = useGetUserDetailQuery();
  const org_id = response?.data?.response?.organization_id;
  initialState = {
    machine_id: machineId,
    organization_id: "",
    site_id: siteDetail,
    operator_id: "",
    service_type: "",
    machine_type: "",
    number_plate: "",
    shift_id: "",
    amount: "",
    service_datetime: "",
    description: "",
    current_reading: "",
    images: [],
    image_url: [],
    urls: [],
    deleted_images: [],
    service_to_time: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(initialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [submitted, setSubmitted] = useState(false);
  const [createMachineService, result] = useCreateMachineServiceMutation();
  const [editMachineService, editResult] = useEditMachineServiceMutation();
  const { data: MachineService } = useGetMachineServiceByIdQuery(id, {
    skip: !id,
  });

  const machineName = mchnData?.find(
    (item: any) => item?._id === id
  )?.machine_name;

  useEffect(() => {
    if (getSiteData?.status === 200) {
      let data = getSiteData?.response;
      setSiteDetail({
        id: data._id,
        name: data.name,
      });
      initialState = {
        ...initialState,
        site_id: {
          id: data._id,
          name: data.name,
        },
      };
    }
  }, [getSiteData]);

  const ShiftDetailData = useGetShiftDetailQuery(siteId);

  const orgId = localStorage.getItem("orgId");
  const UserData = useGetUserDataDetailQuery(siteId);
  const selectUserChange = (event: any) => {
    setFormData({ ...formData, operator_id: event.target.value as string });
  };

  const selectServiceChange = (event: any) => {
    setFormData({ ...formData, service_type: event.target.value as string });
  };

  const selectChange = (event: any) => {
    setFormData({
      ...formData,
      shift_id: event.target.value as string,
    });
  };

  const { data: MachineInfoData } = useGetMachineInfoByIdQuery(paramId, {
    skip: !paramId,
  });

  useEffect(() => {
    if (MachineService?.status === 200) {
      let data: any = MachineService?.response;
      console.log(data, "data");
      const unixTimestamp1 = data.service_datetime;
      const unixTimestamp2 = data.service_to_time;
      const dateObject1 = dayjs(new Date(unixTimestamp1));
      const dateObject2 = dayjs(new Date(unixTimestamp2));
      const selectedServiceType = ServiceType.find(
        (item) => item.title === data.service_type
      );
      console.log(selectedServiceType, "selectededded");

      if (selectedServiceType) {
        setFormData({
          ...formData,
          machine_id: machineId,
          site_id: siteDetail,
          organization_id: data?.organization_id ?? orgId,
          operator_id: data?.userData[0] ?? "",
          shift_id: data?.shiftData[0] ?? "",
          service_type: selectedServiceType.id.toString(),
          amount: data?.amount ?? "",
          service_datetime: dateObject1,
          service_to_time: dateObject2,
          description: data?.description ?? "",
          current_reading: data?.current_reading ?? "",
          images: data?.images?.length
            ? data.images.map((image: any) => image.path)
            : [],
          image_url: data?.encodedUrl?.length ? data.encodedUrl : [],
          urls: data.images?.length
            ? data?.images.map((image: any) => image.fullUrl)
            : [],
        });
      }
    }
    return () => {
      dispatch(setError([]));
    };
  }, [MachineService]);

  if (result.isSuccess && result.data.status === 200) {
    setShowModal(false);
    setMachineEntryEditId("");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    setShowModal(false);
    setMachineEntryEditId("");
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(setError([]));
    setSubmitted(true);
    let data = new FormData();
    let serviceTime =
      formData.service_datetime instanceof Object
        ? new Date(formData.service_datetime as any).valueOf()
        : formData.service_datetime;
    let serviceToTime =
      formData.service_to_time instanceof Object
        ? new Date(formData.service_to_time as any).valueOf()
        : formData.service_to_time;
    console.log(serviceToTime, "servicetotime");
    for (const item of formData.images) {
      data.append("images", item);
    }
    for (const item of formData.deleted_images) {
      data.append("deleted_images", item);
    }

    data.append("site_id", siteDetail?.id !== "" ? siteDetail?.id : "");
    data.append("organization_id", org_id || orgId);

    data.append("machine_id", formData.machine_id ?? machineId);
    data.append("operator_id", formData?.operator_id?._id ?? "");
    data.append("shift_id", formData?.shift_id?._id ?? "");
    data.append(
      "service_type",
      ServiceType[formData?.service_type - 1]?.title || ""
    );

    data.append("description", formData?.description ?? "");
    data.append("current_reading", formData.current_reading);
    data.append("amount", formData.amount);
    data.append("service_datetime", serviceTime as string);
    data.append("service_to_time", serviceToTime as string);
    data.append("id", id ?? (null as any));
    data.append(
      "organization_name",
      MachineInfoData?.response?.organization_name
    );
    id ? editMachineService(data) : createMachineService(data);
  };
  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};
    const numericRegex = /^\d+$/;
    if (!formData.amount) {
      errors.amount = "Remaining Amount is required";
    } else if (!numericRegex.test(formData.amount)) {
      errors.amount = "Remaining Amount must contain only numeric characters";
    }

    if (!formData.current_reading) {
      errors.current_reading = "Current Meter Reading is required";
    } else if (!numericRegex.test(formData.current_reading)) {
      errors.current_reading =
        "Current Meter Reading must contain only numeric characters";
    }

    return errors;
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: Number(value),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };

  const handleChangeComment = (e: React.ChangeEvent<any>) => {
    console.log(e.target.value);
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: [value],
    }));
  };

  const handleChangeImage = (event: any) => {
    let setFileUrl: string[] = [];
    let file = Array.from(event.target.files);
    for (const element of event.target.files) {
      setFileUrl.push(URL.createObjectURL(element));
    }
    setFormData({
      ...formData,
      images: [...formData.images, ...file],
      image_url: [...formData.image_url, ...setFileUrl],
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;
  };

  const handleRemoveImage = useCallback(
    (index: number) => {
      const updatedImages = [...formData.images];
      updatedImages.splice(index, 1);

      let deletedImgArr = [];
      if (formData.urls?.length) {
        deletedImgArr.push(formData.urls[index]);
      }

      const updatedFiles = [...formData.image_url];
      updatedFiles.splice(index, 1);
      setFormData({
        ...formData,
        images: updatedImages,
        image_url: updatedFiles,
        deleted_images: [...formData.deleted_images, ...deletedImgArr],
      });
    },
    [formData]
  );

  const handleDateTimeChange = (fieldName: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: new Date(value).valueOf(),
    }));
  };

  const selectedItem = ServiceType.find(
    (item) => item.id.toString() === formData.service_type
  );
  const showDatePicker =
    selectedItem?.title === "Tyre Repair" ||
    selectedItem?.title === "Regular Service" ||
    selectedItem?.title === "Breakdown";

  const userData = UserData?.data?.response;
  const sortedUserData = [...(userData || [])]?.sort((a: any, b: any) => {
    const firstNameComparison = a.firstname.localeCompare(b.firstname);

    if (firstNameComparison === 0) {
      return a.lastname.localeCompare(b.lastname);
    }
    return firstNameComparison;
  });

  return (
    <Container maxWidth={false} className="p-0">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showModal}>
          <Box sx={style} className="form-outers karuModal">
            <Typography
              id="transition-modal-title"
              variant="h5"
              className="inner-headings"
              component="h2"
            >
              {id && machineName
                ? `Edit Machine Service ${machineName}`
                : "Add Machine Service"}
            </Typography>
            <HighlightOffIcon
              className="closeicon"
              onClick={() => {
                setShowModal(false);
                setMachineEntryEditId("");
              }}
            />

            <Box className="modalBody">
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Service Type *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.service_type}
                          label="Service Type"
                          required
                          onChange={selectServiceChange}
                          renderValue={(selected) => {
                            const selectedItem = ServiceType.find(
                              (item) => item.id.toString() === selected
                            );
                            return selectedItem ? selectedItem.title : "";
                          }}
                        >
                          {ServiceType.map((item) => (
                            <MenuItem value={item.id.toString()} key={item.id}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {showDatePicker && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className="custom-datepicker"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <DateTimePicker
                          label="Service From Time"
                          name="service_datetime"
                          slotProps={{
                            textField: {
                              error: !formData.service_datetime,
                              helperText:
                                submitted && !formData.service_datetime
                                  ? "Working time is required"
                                  : "",
                            },
                          }}
                          value={formData.service_datetime}
                          onChange={(newValue: any) =>
                            handleDateTimeChange("service_datetime", newValue)
                          }
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </LocalizationProvider>
                  )}
                  {showDatePicker && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className="custom-datepicker"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <DateTimePicker
                          label="Service To Time"
                          name="service_to_time"
                          slotProps={{
                            textField: {
                              error: !formData.service_to_time,
                              helperText:
                                submitted && !formData.service_to_time
                                  ? "Working time is required"
                                  : "",
                            },
                          }}
                          value={formData.service_to_time}
                          onChange={(newValue: any) =>
                            handleDateTimeChange("service_to_time", newValue)
                          }
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </LocalizationProvider>
                  )}

                  <Grid item xs={12} md={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Employee Names *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.operator_id as any}
                          label="Employee Name"
                          required
                          onChange={selectUserChange}
                          renderValue={() =>
                            formData.operator_id?.firstname +
                            ` ${formData.operator_id?.lastname} (${
                              formData.operator_id?.ccode +
                              "-" +
                              formData.operator_id?.contact_no
                            })`
                          }
                        >
                          {sortedUserData?.map((item: any) => (
                            <MenuItem
                              value={item}
                              key={item?._id}
                              selected={
                                formData?.operator_id?._id === item?._id
                              }
                            >
                              {item?.firstname +
                                ` ${item?.lastname} (${
                                  item?.ccode + "-" + item?.contact_no
                                })`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Sites Names
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={siteDetail.id}
                          label="Sites Name"
                          renderValue={() => siteDetail?.name}
                        >
                          <MenuItem value={siteDetail?.id}>
                            {siteDetail?.name}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Shifts *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData.shift_id}
                          label="Shifts"
                          required
                          onChange={selectChange}
                          renderValue={() => formData?.shift_id?.shift_name}
                        >
                          {ShiftDetailData?.data?.response.map((item: any) => (
                            <MenuItem value={item} key={item?._id}>
                              {item?.shift_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="amount"
                      label={"Amount" + " (" + "₹" + ")"}
                      name="amount"
                      size="small"
                      required
                      type="number"
                      autoComplete="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      error={!!formErrors.amount}
                      helperText={formErrors.amount}
                    />
                    <Error current_key="amount" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="current_reading"
                      label="Machine Reading"
                      name="current_reading"
                      size="small"
                      required
                      type="number"
                      autoComplete="current_reading"
                      value={formData.current_reading}
                      onChange={handleChange}
                      error={!!formErrors.current_reading}
                      helperText={formErrors.current_reading}
                    />
                    <Error current_key="current_reading" />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="images"
                        name="images"
                        label="Document Images"
                        type="file"
                        className="accessory_img"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ accept: "image/*", multiple: true }}
                        autoComplete="images"
                        onChange={handleChangeImage}
                      />
                      <Error current_key="images" />
                    </Grid>
                    <Grid item xs={12} className="imageDiv">
                      {formData?.image_url?.length
                        ? formData.image_url.map(
                            (image: string, index: number) => (
                              <div className="company-logo" key={index}>
                                <img
                                  src={image}
                                  alt={`fuel_entries-img-${index}`}
                                  className="preview-img"
                                />
                                <button
                                  className="closeimg"
                                  onClick={() => handleRemoveImage(index)}
                                  title="Remove Image"
                                >
                                  <ClearIcon />
                                </button>
                              </div>
                            )
                          )
                        : ""}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      id="description"
                      label="Comment"
                      name="description"
                      type="text"
                      size="small"
                      autoComplete="description"
                      value={formData.description}
                      onChange={handleChangeComment}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="save-btn"
                      onSubmit={handleSubmit}
                      style={{ marginRight: "10px" }}
                      disabled={Object.values(formErrors).some(
                        (error) => !!error
                      )}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      className="close-btn"
                      onClick={() => {
                        setShowModal(false);
                        setMachineEntryEditId("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};
export default memo(AddEditService);
