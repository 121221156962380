import React, { useEffect, useState } from 'react';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import EvStationIcon from '@mui/icons-material/EvStation';
import BadgeIcon from '@mui/icons-material/Badge';
import { setAuthLogout, useGetDashboardDataQuery, useGetUserDetailQuery, UserApi } from '../redux-services';
import { useDispatch } from 'react-redux';

export interface DashboardState {
    user: number;
    employee: number;
    machinery: number;
    fuelCost: number;
    fuelConsumptionLiters: number;
    fuelConsumptionKg: number;
}

const Dashboard = () => {

    const initialState = {
        user: 0,
        employee: 0,
        machinery: 0,
        fuelCost: 0,
        fuelConsumptionLiters: 0,
        fuelConsumptionKg: 0,
    }
    const dispatch = useDispatch();
    const [isSuperAdmin, setIsSuperAdmin] = useState<any>(false);
    const [dashboardData, setDashboardData] = useState<DashboardState>(initialState);
    const username = localStorage.getItem("username");
    const {data: userDetail} = useGetUserDetailQuery();
    const userRole = userDetail?.response?.role_type;
    
    useEffect(()=>{
    if(userRole === 0){
        setIsSuperAdmin(true);
    }},[userRole]);
    
    console.log(userRole);
    const { isError, isLoading, data } = useGetDashboardDataQuery();

    useEffect(() => {
        if (username) {
            setDashboardData(initialState);
            dispatch(UserApi.util.invalidateTags(["userTag"]));
        }
    }, [username, dispatch]);
    
    useEffect(() => {
        if (data?.response) {
            const result = data.response;
            setDashboardData({
                user: result?.user < 10 ? '0' + result?.user : result?.user,
                employee: result?.employee < 10 ? '0' + result?.employee : result?.employee,
                machinery: result?.machinery < 10 ? '0' + result?.machinery : result?.machinery,
                fuelCost: result?.totalFuelCost ?? 0,
                fuelConsumptionLiters: result?.totalFuelConsumptionLiters ?? 0,
                fuelConsumptionKg: result?.totalFuelConsumptionKg ?? 0,
            });
        }
    
        if (!isLoading && isError) {
            dispatch(setAuthLogout());
        }
    }, [data, isLoading, isError]);
    

    return (
        <div>
            <div className='main-title'>
                <h3 className="m-0">Dashboard</h3>
            </div>

            <div className='main-cards'>
                {username !== "Super Admin" && (
                    <div className='card'>
                        <div className='card-inner'>
                            <h3 className="m-0">Machinery</h3>
                            <PrecisionManufacturingIcon className='card_icon' />
                        </div>
                        <h1>{dashboardData?.machinery}</h1>
                    </div>
                )}

                <div className='card'>
                    <div className='card-inner'>
                        <h3 className="m-0">User</h3>
                        <BadgeIcon className='card_icon' />
                    </div>
                    <h1>{dashboardData?.user}</h1>
                </div>

                { !isSuperAdmin && (<div className='card'>
                    <div className='card-inner'>
                        <h3 className="m-0">Employee</h3>
                        <BadgeIcon className='card_icon' />
                    </div>
                    <h1>{dashboardData?.employee}</h1>
                </div>)}
                {/* Commented temporary */}
                
                {/* {username !== "Super Admin" && (
                    <div className='card'>
                        <div className='card-inner'>
                            <h3 className="m-0">
                                Fuel Logs ({new Date().toLocaleString('default', { month: 'short' }) + '/' + new Date().getFullYear()})
                            </h3>
                            <EvStationIcon className='card_icon' />
                        </div>
                        <div className=''>
                            <h4>Cost: {dashboardData?.fuelCost ?? 0} Rupees</h4>
                            <h4>Consumption: {dashboardData?.fuelConsumptionLiters ?? 0} Liters, {dashboardData?.fuelConsumptionKg ?? 0} Kg</h4>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    );
}

export default Dashboard;
