import React, { memo, useCallback, useEffect, useState } from "react";
import arrowImage from "../../../assests/img/arrow.svg";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetUserDetailQuery,
  useGetVehicleDaywiseHistoryQuery,
  useGetMachineInfoByIdQuery
} from "../../../redux-services";
import VehicleInfoModal from "../../Sites/ViewSites/TrackerDetails/ViewTracker/VehicleDetails/VehicleInfoModal";
import { ColumnsType, Order, PageMeta } from "../../../types/CommonTypes";
import { TableBodyElement } from "../../../components/TableBody/TableBodyElement";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const tableColumns: ColumnsType[] = [
  { id: "", sort: true, label: "" },
  { id: "reg_no", sort: true, label: "Reg. No." },
  { id: "vin_no", sort: true, label: "Vin. No." },
  { id: "model_name", sort: false, label: "Model Name" },
  { id: "odometer", sort: true, label: "Odometer" },
  { id: "speed", sort: true, label: "Speed" },
  { id: "fuel", sort: true, label: "Fuel" },
  { id: "ignition", sort: true, label: "Ignition" },
  { id: "reported_at", sort: true, label: "Reported At" },
  { id: "address", sort: true, label: "Address" },
];

const MachineHistory = () => {
  const formatDate = (date: any) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    const year = d.getFullYear();
    return [year, month, day].join("-");
  };
  const currentDate = formatDate(new Date());
  const initialState: PageMeta = {
    page: 1,
    per_page: 20,
    searchText: "",
    startDate: currentDate,
    endDate: currentDate,
    order: (process.env.REACT_APP_ORDER as Order) || "desc",
    order_by: process.env.REACT_APP_ORDER_BY || "createdAt",
  };

  const navigate = useNavigate();
  const { data: userData } = useGetUserDetailQuery();
  const username = userData?.response?.username;
  const [MachineName, setMachineName] = useState<string>('');

  const [currentUrl, setCurrentUrl] = useState("");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState?.per_page ?? 10
  );
  const [mapCoordinates, setMapCoordinates] = useState({ lat: 0, lon: 0 });
  const [open, setOpen] = useState<boolean>(false);
  const [vehicleAddress, setVehicleAddress] = useState("");
  const { siteId } = useParams();
  const [vehicleDaywiseHistory, setVehicleDaywiseHistory] = useState<any[]>([]);
  let machineId: any;
  if (siteId) {
    const match = currentUrl.match(/\/machine\/([^\/]+)\/history\//);
    machineId = match ? match[1] : null;
  } else {
    const match = currentUrl.match(/\/([^\/]+)\/history$/);
    machineId = match ? match[1] : null;
  }
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  const { data: getData } = useGetMachineInfoByIdQuery(machineId, {
    skip: !machineId,
});
useEffect(() => {
    if (getData?.status === 200) {
        let data = getData?.response ;
        const machineType = data?.machine_type ?? '';
        const numberPlate = data?.number_plate ?? '';
        if (numberPlate) {
            const lastFourDigits = numberPlate.slice(-4);
            setMachineName(`${machineType}-${lastFourDigits}`);
        } else {
            setMachineName(machineType);
        }
    }
}, [getData]);

  const [fromDate, setFromDate] = useState(currentDate);

  const handleDateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedDate = event.target.value;
    setFromDate(selectedDate);
  };

  const { data: vehicleDaywiseHistoryData } = useGetVehicleDaywiseHistoryQuery(
    {
      pageData: {
        per_page: rowsPerPage,
        page: page + 1,
        order: initialState.order,
        order_by: initialState.order_by,
        searchText: initialState.searchText,
        startDate: fromDate,
        endDate: currentDate,
      },
      id: machineId || "",
      siteId: siteId || "",
    },
    {
      skip: !machineId,
    }
  );
  const location = useLocation();
  const fromUrl = location?.state?.from;
  useEffect(() => {
    if (vehicleDaywiseHistoryData) {
      if (
        vehicleDaywiseHistoryData.response &&
        Array.isArray(vehicleDaywiseHistoryData.response.data)
      ) {
        const transformedData = vehicleDaywiseHistoryData.response.data.flatMap(
          (item: any) => {
            if (Array.isArray(item.entries)) {
              const transformedEntries = item.entries.map((entry: any) => ({
                ...entry,
                ignition: entry.ignition ? "Yes" : "No",
              }));
              return transformedEntries;
            } else if (typeof item.entries === "object") {
              const transformedEntry = {
                ...item.entries,
                ignition: item.entries.ignition ? "Yes" : "No",
              };
              return [transformedEntry];
            } else {
              return [];
            }
          }
        );

        transformedData?.sort(
          (a: any, b: any) =>
            new Date(a.reported_at).getTime() -
            new Date(b.reported_at).getTime()
        );

        setVehicleDaywiseHistory(transformedData);
      } else {
        setVehicleDaywiseHistory([]);
        console.error("Unexpected data structure:", vehicleDaywiseHistoryData);
      }
    }
  }, [vehicleDaywiseHistoryData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewAddress = (id: string) => {
    const res = vehicleDaywiseHistory.find((data) => data._id === id);
    if (res) {
      const { lat, lon, address } = res;
      setMapCoordinates({ lat, lon });
      setVehicleAddress(address);
      setOpen(true);
    }
  };

  const handleModel = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);
  const breadcrumbs = [
    { name: 'Machinery Lists', to: `/admin/machine` },
    { name: `Machinery History(${MachineName})`, to: `admin/site/machine/${machineId}/history` },
];

  return (
    <>
      <div>
        <Paper className="form-outers">
        <Toolbar>
                    <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                        {breadcrumbs.map((crumb, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                                {index === breadcrumbs.length - 1 ? (
                                    <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                                ) : (
                                    <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </Toolbar>
          <Toolbar>
            <Typography
              sx={{ flex: "1 1 40%" }}
              variant="h5"
              id="tableTitle"
              className="inner-headings"
              component="div"
            >
              Vehicle Daywise History
            </Typography>
            <TextField
              style={{ width: "20%" }}
              id="from_date"
              label="From Date"
              name="from_date"
              className="calendradate"
              type="date"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="from_date"
              value={fromDate}
              onChange={handleDateChange}
              inputProps={{
                max: currentDate,
              }}
            />
          </Toolbar>
          <TableContainer component={Paper} className="form-outers">
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              className="table-outers"
            >
              <TableHead>
                <TableRow>
                  {tableColumns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableBodyElement
                  selectedColumns={[
                    "reg_no",
                    "vin_no",
                    "model_name",
                    "odometer",
                    "speed",
                    "fuel",
                    "ignition",
                    "reported_at",
                    "address",
                  ]}
                  viewAddress={(id: any) => {
                    handleViewAddress(id);
                  }}
                  setData={vehicleDaywiseHistory || []}
                />
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={vehicleDaywiseHistoryData?.response?.total ?? 0}
              page={vehicleDaywiseHistoryData?.response?.page ?? 0}
              rowsPerPageOptions={[20, 50, 100]}
              onPageChange={handleChangePage}
              rowsPerPage={
                rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </div>
      <div>
        <Typography variant="h6" gutterBottom>
          {open && (
            <VehicleInfoModal
              open={open}
              handleModel={() => handleModel(false)}
              lat={mapCoordinates.lat}
              lon={mapCoordinates.lon}
              veh_address={vehicleAddress}
            />
          )}
        </Typography>
      </div>
    </>
  );
};

export default memo(MachineHistory);
