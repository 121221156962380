import { createApi } from "@reduxjs/toolkit/query/react";

import { PageMeta, PaginatedData, Response } from "../../types/CommonTypes";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const SitesApi = createApi({
  reducerPath: "sitesApi",

  baseQuery: axiosBaseQuery(),

  keepUnusedDataFor: 0,

  tagTypes: ["sitesTag", "FuelStorage"],

  endpoints: (builder) => ({
    sitesList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/sites/getlist`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["sitesTag"],
    }),
    sitesListByOrgId: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/sites/organizationId`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          organization_id: pageData.organization_id ?? "",
        },
      }),
      providesTags: ["sitesTag"],
    }),
    sitesListForDropDownByOrgId: builder.query<
      Response<any>,
      string | undefined
    >({
      query: (org_id: string | undefined) => ({
        url: `/sites/getSitesDropDown/${org_id}`,
        method: "GET",
      }),
      providesTags: ["sitesTag"],
    }),
    createSites: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/sites/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["sitesTag"],
    }),
    getVehicleDetails: builder.query<
      Response<any>,
      { id: string | undefined; siteId: string | undefined }
    >({
      query: ({ id, siteId }) => ({
        url: `/sites/getVehicleDetails/${id}/${siteId}`,
        method: "GET",
      }),
      providesTags: ["sitesTag"],
    }),

    getSitesById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/getSites/${id}`,
        method: "GET",
      }),
      providesTags: ["sitesTag"],
    }),
    updateSiteStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/sites/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["sitesTag"],
    }),

    editSites: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/sites/edit/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["sitesTag"],
    }),
    deleteSites: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sitesTag"],
    }),
    deactivateSites: builder.mutation<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/deactivate/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sitesTag"],
    }),
    createFuelStorage: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/fuel_storage/create`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["FuelStorage"],
    }),
    editFuelStorage: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/fuel_storage/edit/${data.get("storageId")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["FuelStorage"],
    }),
    getFuelStorageById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/fuel_storage/${id}`,
        method: "GET",
      }),
      providesTags: ["FuelStorage"],
    }),
    fuelStorageList: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/fuel_storage`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
          isDocument: pageData.isDocument ?? false,
          id: pageData.id ?? "",
        },
      }),
      providesTags: ["FuelStorage"],
    }),
    getFuelTransaction: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/fuel_storage/get_fuel_transaction/${pageData.id}`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
    }),
    deleteFuelTransactionInfo: builder.mutation<
      Response<any>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/fuel_storage/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FuelStorage"],
    }),
    getFuelTransactionById: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/fuel_storage/get_fuel_transactions/${id}`,
        method: "GET",
      }),
      providesTags: ["FuelStorage"],
    }),
    editFuelTransaction: builder.mutation<Response<any>, any>({
      query: (data: any) => ({
        url: `/fuel_storage/edit_Fuel_Transaction/${data.get("transactionId")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["FuelStorage"],
    }),
    updateFuelStorageStatus: builder.mutation<
      Response<any>,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        url: `/fuel_storage/updateStatus/${id}`,
        method: "POST",
        body: { status },
      }),
      invalidatesTags: ["FuelStorage"],
    }),
    allFuelStorages: builder.query<Response<PaginatedData<any>>, PageMeta>({
      query: (pageData: PageMeta) => ({
        url: `/fuel_storage/get_all_fuel_storages`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["FuelStorage"],
    }),
    getShiftsBySiteId: builder.query<Response<any>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/sites/getShiftsForTracker/${id}`,
        method: "GET",
      }),
      providesTags: ["sitesTag"],
    }),
  }),
});

export const {
  useSitesListQuery,
  useGetVehicleDetailsQuery,
  useSitesListByOrgIdQuery,
  useSitesListForDropDownByOrgIdQuery,
  useCreateSitesMutation,
  useGetSitesByIdQuery,
  useEditSitesMutation,
  useDeleteSitesMutation,
  useCreateFuelStorageMutation,
  useEditFuelStorageMutation,
  useFuelStorageListQuery,
  useGetFuelStorageByIdQuery,
  useGetFuelTransactionQuery,
  useEditFuelTransactionMutation,
  useGetFuelTransactionByIdQuery,
  useDeleteFuelTransactionInfoMutation,
  useUpdateFuelStorageStatusMutation,
  useUpdateSiteStatusMutation,
  useDeactivateSitesMutation,
  useAllFuelStoragesQuery,
  useGetShiftsBySiteIdQuery,
} = SitesApi;
