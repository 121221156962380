import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { useGetSitesByIdQuery } from "../../redux-services";

export default function CollapsibleTable(props: any) {
  const { data, subTableData, selectedColumns } = props;
  const [open, setOpen] = React.useState(false);
  let { id: siteId } = useParams();
  const [buttonDisabled, setIsButtonDisabled] = useState("")

  const { data: getData } = useGetSitesByIdQuery(siteId);
  useEffect(() => {
    if (getData?.status === 200) {
      let data = getData?.response;
      setIsButtonDisabled(data.is_deactivate)
    }
  }, [getData]);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {selectedColumns?.length > 0 && selectedColumns?.map((col: any) =>
          <TableCell align="left">{data[col]}</TableCell>
        )}
      </TableRow>
      {subTableData?.length > 0 && subTableData?.map((subArr: any, index: any) =>
        <TableRow key={index} className={subArr?.class ?? ''}>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {subArr?.title}
                </Typography>
                {data[subArr?.key]?.length === 0 ?
                  <Box style={{ width: "100%", textAlign: "center" }}>
                    <span style={{ display: "block", paddingBottom: "5px" }}>
                      No Record Found
                    </span>
                  </Box>
                  :
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">S.No.</TableCell>
                        {subArr?.data?.length > 0 && subArr?.data?.map((head: any) =>
                          <TableCell align="left">{head?.title}</TableCell>
                        )}
                        {subArr?.isEdit &&
                          <TableCell align="left">Action</TableCell>
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[subArr?.key]?.length > 0 ? data[subArr?.key]?.map((historyRow: any, subIndex: any) => (
                        <TableRow key={subIndex}>
                          <TableCell align="left">
                            {subIndex + 1}
                          </TableCell>
                          {subArr?.data?.length > 0 && subArr?.data?.map((bodyData: any) =>
                            <TableCell align="left">
                              {historyRow[bodyData?.key]}
                            </TableCell>
                          )}
                          {subArr?.isEdit && subArr?.key === "work_log_data" &&
                            <TableCell>
                              {!buttonDisabled &&
                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?.id, historyRow?.work_item_id)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                          {subArr?.isEdit && subArr?.key === "service_record" &&
                            <TableCell>
                              {!buttonDisabled &&

                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?.id, historyRow?.machine_id, historyRow?.site_id, historyRow?.machine_name)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                          {subArr?.isEdit && subArr?.key === "fuel_consumption" &&
                            <TableCell>
                              {!buttonDisabled &&

                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?.id, historyRow?.machine_id, historyRow?.site_id)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                          {subArr?.isEdit && subArr?.key === "attendance_status" &&
                            <TableCell>
                              {!buttonDisabled &&
                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?._id, historyRow?.site_id)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }

                          {subArr?.isEdit && subArr?.key === "work_log" &&
                            <TableCell>
                              {!buttonDisabled &&

                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?.id, historyRow?.work_item_id)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                          {subArr?.isEdit && subArr?.key === "daily_logs" &&
                            <TableCell>
                              {!buttonDisabled &&
                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?._id, historyRow?.site_id)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                          {subArr?.isEdit && subArr?.key === "transactions" &&
                            <TableCell>
                              {!buttonDisabled &&
                                <Tooltip title="Edit" arrow className="me-1 mt-1">
                                  <Button
                                    onClick={() =>
                                      subArr.editFn(historyRow?.id)
                                    }
                                    className="action-btn"
                                  >
                                    <EditIcon />
                                  </Button>
                                </Tooltip>
                              }
                            </TableCell>
                          }
                        </TableRow>
                      )) :
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }} colSpan={12}>
                            No Record Found
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                }
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
