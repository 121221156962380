import { Box, Button,Tab, Tabs, Toolbar, Typography } from '@mui/material';
import React, { useCallback, useState, useEffect
 } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import arrowImage from "../../../assests/img/arrow.svg"
import SiteDetails from './SiteDetails';
import SiteMachineInfo from './SiteMachinery/indes';
import SiteUserList from './SiteEmployees';
import FuelStorage from './FuelStorage';
import { useGetSitesByIdQuery } from '../../../redux-services';
import BoreArea from './BoreArea';
import WorkItem from './WorkItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import RateChartCard from '../RateChartCard/RateChartCard';
import MonthlyReport from './MonthlyReport';
import TrackVehicleMovement from '../../VehicleMovement';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

/**
 * @param {TabPanelProps} props - Properties for the tab panel.
 * @returns {JSX.Element} JSX element representing the tab panel.
 */
let CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`sites-tabpanel-${index}`}
            aria-labelledby={`sites-tab-${index}`}
            {...other}
            className="DarkTabpanel"

        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/**
 * Generates accessibility properties for tab components based on the provided index.
 * These properties include unique identifiers for each tab and associations with their corresponding panels.
 * @param {number} index - The index of the tab.
 * @returns {Object} An object containing accessibility properties for the tab.
 */
let a11yProps = (index: number) => {
    return {
        id: `sites-tab-${index}`,
        'aria-controls': `sites-tabpanel-${index}`,
    };
}

/**
 * @returns {JSX.Element} 
 */
const ViewSites: React.FC = () => {
    const { id } = useParams<string>();
    const [value, setValue] = useState<number>(
        localStorage.getItem('selectedTab') ? parseInt(localStorage.getItem('selectedTab') || '0', 10) : 0
    );
    const navigate = useNavigate();
    const userDetail = useSelector((state: RootState) => state.auth.user);

    window.history.pushState({ prevUrl: window.location.href }, "");


    const siteId: any = id
    const queryParams = new URLSearchParams();
    queryParams.set('siteId', siteId);

    const [siteDetail, setSiteDetail] = useState({
        id: '',
        name: '',
    });
    const [orgDetail, setOrgDetail] = useState({
        organizationId: '',
        organizationName: ''
    })
    const { data: getData } = useGetSitesByIdQuery(id);
    useEffect(() => {
        if (getData?.status === 200) {
            let data = getData?.response;
            setSiteDetail({
                id: data._id,
                name: data.name,
            });
            setOrgDetail({
                organizationId: data.organization_id[0]._id,
                organizationName: data.organization_id[0].organization_name

            });
        }
    }, [getData]);

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        localStorage.setItem('selectedTab', newValue.toString());
    }, []);

    const breadcrumbs = [
        ...(userDetail?.role_type === 0 ? [{ name: 'Organizations', to: '/admin/organization' }] : []),
        { name: orgDetail.organizationName, to: `/admin/organization/${orgDetail.organizationId}` },
        { name: siteDetail.name, to: `/admin/organization/sites/${siteDetail.id}` },
    ];

    return (
        <Box sx={{ flexGrow: 1 }} className='mt-3'>
            <Toolbar>
                <div className="breadcrumbs" style={{ display: 'flex', alignItems: 'center' }}>
                    {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={index}>
                            {index > 0 && <img src={arrowImage} alt="arrow" style={{ margin: '0 8px', verticalAlign: 'middle' }} />}
                            {index === breadcrumbs.length - 1 ? (
                                <Typography variant="body2" component="span" style={{ color: 'white' }}>{crumb.name}</Typography>
                            ) : (
                                <Link to={crumb.to} className="breadcrumb-link" style={{ color: 'white' }}>{crumb.name}</Link>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Toolbar>
                <Typography 
                variant="h5" 
                sx={{ fontWeight: '900', color: '#ADD8E6', marginTop: 1, marginLeft: 2 }}
            >
                {siteDetail.name}
            </Typography>
            {/* Container for the tabs */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {/* Tabs component to render the tab headers */}
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Site Details" {...a11yProps(0)} />
                    <Tab label="Machinery" {...a11yProps(1)} />
                    <Tab label="Employees" {...a11yProps(2)} />
                    <Tab label="Fuel Storage" {...a11yProps(3)} />
                    {/* <Tab label="Fuel Type" {...a11yProps(4)} /> */}
                    {/* <Tab label="Tracker Details" {...a11yProps(5)} /> */}
                    <Tab label="Bore Area" {...a11yProps(4)} />
                    {/* <Tab label="Site Work Log" {...a11yProps(7)} /> */}
                    <Tab label="Work Items" {...a11yProps(5)} />
                    {/* <Tab label="Daily Logs" {...a11yProps(8)} /> */}
                    <Tab label="Monthly Report" {...a11yProps(6)} />
                    <Tab label="Replay Vehicle" {...a11yProps(7)} />
                    {/* <Tab label="Rate Card" {...a11yProps(8)} /> */}
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0} >

                {typeof id !== 'undefined' && <SiteDetails org_id={orgDetail?.organizationId} id={id} setSiteDetail={setSiteDetail} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Box>
                    <SiteMachineInfo siteDetail={siteDetail} />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Box>
                    <SiteUserList siteDetail={siteDetail} />
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Box> <FuelStorage /> </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <Box> <BoreArea siteDetail={siteDetail} /> </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <Box> <WorkItem siteDetail={siteDetail} /> </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={6}>
                <Box> <MonthlyReport siteDetail={siteDetail} /> </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={7}>
                <Box>
                    <TrackVehicleMovement siteDetail={siteDetail} />
                </Box>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={8}>
                <Box>
                    <RateChartCard siteDetail={siteDetail} />
                </Box>
            </CustomTabPanel> */}
        </Box>
    )
}

export default ViewSites