import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  ColumnsType,
  Order,
  PageMeta,
  SortingData,
} from "../../types/CommonTypes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SearchElement } from "../../components/Search/Search";
import {
  useAllFuelStoragesQuery,
  useDeleteFuelTypeMutation,
  useFuelTypeListQuery,
} from "../../redux-services";
import AddEditFuelType from "./AddEditFuelType";
import { FuelTypes, FuelUnit } from "../../ constants";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import arrowImage from "../../assests/img/arrow.svg";

const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "S.No.",
  },
  {
    id: "fuel_type",
    sort: true,
    label: "Fuel Type",
  },
  {
    id: "fuel_unit",
    sort: true,
    label: "Fuel Unit",
  },
  {
    id: "company_name",
    sort: true,
    label: "Company Name",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
  },
];

interface FuelTypeTableProps {
  fuelStorageData: any[];
  fuelStorageList: any[];
  rowsPerPage: number;
  order: Order;
  orderBy: keyof SortingData;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => void;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  setIsClose: (isClose: boolean) => void;
  setId: (id: string) => void;
  editForm: (id: string) => void;
  data: any;
  setFuelTypeInUse: any;
}

let initialOrder: string = process.env.REACT_APP_ORDER as string;

const FuelTypeTable: FC<FuelTypeTableProps> = ({
  fuelStorageData,
  fuelStorageList,
  rowsPerPage,
  order,
  orderBy,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  setId,
  editForm,
  setIsClose,
  data,
  setFuelTypeInUse,
}) => {
  return (
    <TableContainer component={Paper} className="table-outers">
      <Table
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
        className="table-outers"
      >
        <TableHead>
          <TableRow>
            <TableHeadElement
              setHead={tableColumns ?? []}
              order={order}
              orderBy={orderBy}
              sortFn={(event: any, id: string) =>
                handleRequestSort(event, id as keyof SortingData)
              }
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableBodyElement
            selectedColumns={["fuel_type", "fuel_unit", "company_name"]}
            setData={fuelStorageData}
            deleteFn={(id: string) => {
              const fuelTypeInsUse = fuelStorageList?.filter(
                (item: any) => item?.fuel_type?.toString() === id
              );

              if (fuelTypeInsUse && fuelTypeInsUse.length > 0) {
                setFuelTypeInUse(true);
              } else {
                setFuelTypeInUse(false);
              }

              setIsClose(true);
              setId(id);
            }}
            editFn={(id: string) => editForm(id)}
            pageData={{
              limit: rowsPerPage,
              page: data?.response.page as number,
            }}
          />
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={data?.response.total ?? 0}
        page={data?.response.page ?? 0}
        rowsPerPageOptions={[20, 50, 100]}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

const FuelTypeList: FC = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };

  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number(process.env.REACT_APP_PER_PAGE)
  );
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const navigate = useNavigate();
  const query = useLocation();

  const [fuelTypeList, setFuelTypeList] =
    useState<PageMeta>(initialState);
  const [fuelStorageData, setFuelStorageData] = useState<[]>([]);
  const [editId, setEditId] = useState<string>("");
  const [isClose, setIsClose] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [fuelTypeInUse, setFuelTypeInUse] = useState(false);

  const { isSuccess, data } = useFuelTypeListQuery({
    ...fuelTypeList,
  });

  const { data: fuelStorageAllData } = useAllFuelStoragesQuery({
    ...fuelTypeList,
  });

  const [deleteFuelType] = useDeleteFuelTypeMutation();

  const fuelStorageList = fuelStorageAllData?.response?.data;

  useEffect(() => {
    handleFuelTypeData();
  }, [isSuccess, data]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setFuelTypeList({
      ...fuelTypeList,
      order: newOrder,
      order_by: property,
    });
  };

  const handleFuelTypeData = () => {
    if (isSuccess) {
      const fData = data?.response.data.map((item: any) => {
        const FuelType = FuelTypes.find(
          (type: { id: number; title: string }) => type.id === item.fuel_type
        );

        return {
          ...item,
          company_name: `${item.company_name}`,
          fuel_unit: `${FuelUnit[item.fuel_unit]}`,
          fuel_type: FuelType?.title ?? "Other",
        };
      });
      setFuelStorageData(fData);
    } else {
      setFuelStorageData([]);
    }
  };

  const searchHandler = useMemo(
    () =>
      debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        setFuelTypeList({
          ...fuelTypeList,
          page: 1,
          searchText: event.target.value,
        });
      }, 800),
    [fuelTypeList]
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFuelTypeList({ ...fuelTypeList, page: newPage + 1 });
    if (query.state !== null) navigate(query.pathname, {});
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(Number(event.target.value) as unknown as number);
    setFuelTypeList({
      ...fuelTypeList,
      per_page: parseInt(event.target.value, 10),
      page: 1,
    });
  };

  const confirmBoxClose = (confirmed: boolean) => {
    const fuelTypeUsed = fuelStorageList?.some(
      (item: any) => item?.fuel_type === editId
    );
    setFuelTypeInUse(fuelTypeUsed);
    if (confirmed && !fuelTypeUsed) {
      setIsClose(false);
      deleteFuelType(editId);
    } else {
      setIsClose(false);
    }
  };

  const handleEditForm = (id: string) => {
    setEditId(id);
    setOpen(true);
  };

  const breadcrumbs = [
    { name: "Dashboard", to: "/admin/dashboard" },
    { name: "FuelType-List", to: "/admin/fuel-type" },
  ];

  const handleAddFuelType = () => {
    setEditId("");
    setOpen(true);
  };

  return (
    <>
      <Paper className="table-outers mb-2">
        <BreadcrumbsComponent breadcrumbs={breadcrumbs} />
        <FuelTypeHeader
          searchHandler={searchHandler}
          handleAddFuelType={handleAddFuelType}
        />
        <FuelTypeTable
          fuelStorageData={fuelStorageData}
          fuelStorageList={fuelStorageList}
          handleChangePage={handlePageChange}
          handleChangeRowsPerPage={handleRowsPerPageChange}
          editForm={handleEditForm}
          setId={(id: string) => {
            setIsClose(true);
            setEditId(id);
          }}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          setIsClose={setIsClose}
          data={data}
          setFuelTypeInUse={setFuelTypeInUse}
        />
      </Paper>
      <ConfirmBox
        title="Fuel Type Delete Confirmation"
        message={
          fuelTypeInUse
            ? "Fuel Type already in use"
            : "Are you sure you want to delete this fuel type?"
        }
        open={isClose}
        onClose={confirmBoxClose}
        showSubmitButton={!fuelTypeInUse}
      />
      {open && (
        <AddEditFuelType
          open={open}
          handleModel={setOpen}
          fuelTypeId={editId}
        />
      )}
    </>
  );
};

const BreadcrumbsComponent = ({
  breadcrumbs,
}: {
  breadcrumbs: { name: string; to: string }[];
}) => (
  <Toolbar>
    <div
      className="breadcrumbs"
      style={{ display: "flex", alignItems: "center" }}
    >
      {breadcrumbs.map((crumb, index) => (
        <React.Fragment key={crumb.name}>
          {index > 0 && (
            <img
              src={arrowImage}
              alt="arrow"
              style={{ margin: "0 8px", verticalAlign: "middle" }}
            />
          )}
          {index === breadcrumbs.length - 1 ? (
            <Typography
              variant="body2"
              component="span"
              style={{ color: "white" }}
            >
              {crumb.name}
            </Typography>
          ) : (
            <Link
              to={crumb.to}
              className="breadcrumb-link"
              style={{ color: "white" }}
            >
              {crumb.name}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  </Toolbar>
);

const FuelTypeHeader = ({
  searchHandler,
  handleAddFuelType,
}: {
  searchHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleAddFuelType: () => void;
}) => (
  <Toolbar>
    <Typography
      sx={{ flex: "1 1 40%" }}
      variant="h5"
      id="tableTitle"
      component="div"
      className="inner-headings"
    >
      Fuel Type
    </Typography>
    <div className="flex-boxinner">
      <Typography sx={{ flex: "1 1 auto" }}>
        <SearchElement
          searchFn={searchHandler}
          searchTag="Search by Company Name..."
        />
      </Typography>
      <Tooltip title="Add Fuel Type" arrow className="me-1 mt-1">
        <Button
          className="secondary-btn"
          onClick={handleAddFuelType}
          variant="contained"
          color="primary"
        >
          Add Fuel Type
        </Button>
      </Tooltip>
    </div>
  </Toolbar>
);

export default FuelTypeList;
