import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setError,
  useCreateMachineInfoMutation,
  useEditMachineInfoMutation,
  useGetMachineByOrgIdQuery,
  useGetMachineInfoByIdQuery,
  useGetSitesDetailQuery,
  useMachineInfoListQuery,
} from "../../redux-services";
import Error from "../../components/ErrorBox/Error";
import ReusableAddEditMachineInfo from "./ReusableAddEditMahineInfo";
import { Order, PageMeta, SortingData } from "../../types/CommonTypes";
import { RootState } from "../../store/store";

export interface UserState {
  site_id: any;
  machine_id: any;
  machine_type: any;
  [key: string]: any;
  company: string;
  machine_model: string;
  number_plate: string;
  purchase_date: string;
  manufacturing_date: string;
  tank_capacity: string;
  insurance_amount: string;
  volume_capacity: string;
  expiration_date: string;
  isSiteChange: boolean;
  isMachineChange: boolean;
  is_rented: boolean;
  is_loanable: boolean;
  rented_amount: any;
  security_amount: any;
  loan_duration: any;
  financer_name: any;
  monthly_installment: any;
  measurement_unit: string;
}
interface FormErrors {
  machine_type?: string;
  company?: string;
  machine_model?: string;
  number_plate?: string;
  purchase_date?: string;
  manufacturing_date?: string;
  tank_capacity?: string;
  volume_capacity?: string;
  insurance_amount?: string;
  expiration_date?: string;
}
let initialOrder: string = process.env.REACT_APP_ORDER as string;

const AddEditMachineInfo = () => {
  const initialState: PageMeta = {
    page: 1,
    per_page: Number(process.env.REACT_APP_PER_PAGE),
    searchText: "",
    order: process.env.REACT_APP_ORDER,
    order_by: process.env.REACT_APP_ORDER_BY,
  };
  const navigate = useNavigate();
  const { id } = useParams();
  const intialState = {
    site_id: "",
    machine_id: "",
    machine_type: "",
    company: "",
    machine_model: "",
    number_plate: "",
    expiration_date: "",
    purchase_date: "",
    manufacturing_date: "",
    tank_capacity: "",
    volume_capacity: "",
    isSiteChange: false,
    isMachineChange: false,
    is_rented: false,
    is_loanable: false,
    insurance_amount: "",
    rented_amount: 0,
    security_amount: 0,
    financer_name: "",
    monthly_installment: 0,
    measurement_unit: "",
    loan_duration: 0,
    org_id: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [orderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );

  const [orgId, setOrgId] = useState<string>("");
  const [order] = useState<Order>(initialOrder as Order);
  const [existingNumberPlates, setExistingNumberPlates] = useState<string[]>(
    []
  );
  const [machineInfoList] = useState<PageMeta>(initialState);
  const [rowsPerPage] = useState<number>(initialState.per_page as number);
  let query = useLocation();
  const [search] = useState<string>("");
  const userLoginDetail = useSelector((state: RootState) => state.auth);
  const [createMachineInfo, result] = useCreateMachineInfoMutation();
  const [editMachineInfo, editResult] = useEditMachineInfoMutation();
  const { data: MachineInfoData } = useGetMachineInfoByIdQuery(id, {
    skip: !id,
  });
  const [showLoanCheckBox, setShowLoanCheckBox] = useState(true);
  const [showRentedCheckBox, setShowRentedCheckBox] = useState(true);
  const machineTypeData = useGetMachineByOrgIdQuery(orgId, { skip: !orgId });
  const { refetch, ...SitesData } = useGetSitesDetailQuery(undefined);

  const { data } = useMachineInfoListQuery({
    per_page: machineInfoList.per_page ?? rowsPerPage,
    page: query.state ?? machineInfoList.page,
    order: machineInfoList.order ?? order,
    order_by: machineInfoList.order_by ?? orderBy,
    searchText: machineInfoList.searchText ?? search,
  });
  useEffect(() => {
    if (userLoginDetail?.user?.organization_id) {
      setOrgId(userLoginDetail?.user?.organization_id);
    } else {
      setOrgId(localStorage.getItem("orgId") ?? "");
    }
  }, [userLoginDetail?.user?.organization_id]);

  useEffect(() => {
    const plates: string[] =
      data?.response?.data?.map(
        (machine: { number_plate: any }) => machine.number_plate
      ) ?? [];
      console.log(plates,"plates")
    setExistingNumberPlates(plates);
    if (MachineInfoData?.status === 200) {
      let data = MachineInfoData?.response;
      let siteIdData = data?.siteData[0];
      let machineIdData = data?.machinetypeData;
      siteIdData = {
        _id: siteIdData?._id?.length === 0 ? "" : siteIdData?._id,
        name: siteIdData?.name?.length === 0 ? "" : siteIdData?.name,
      };
      let purchaseDate = "";
      if (data?.purchase_date) {
        const timestamp = data.purchase_date;
        if (!isNaN(timestamp)) {
          purchaseDate = new Date(timestamp)?.toISOString()?.split("T")[0];
        }
      }
      if (id) {
        setExistingNumberPlates(data?.number_plates);
      }
      setFormData({
        ...formData,
        machine_id: machineIdData ?? [],
        machine_name: machineIdData?._id ?? [],
        site_id: siteIdData ?? [],
        company: data?.company ?? "",
        machine_model: data?.machine_model ?? "",
        number_plate: data?.number_plate ?? "",
        purchase_date: purchaseDate,
        manufacturing_date: data?.manufacturing_date ?? "",
        volume_capacity: data?.volume_capacity ?? "",
        tank_capacity: data?.tank_capacity ?? "",
        is_rented: data?.is_rented ?? false,
        is_loanable: data?.is_loanable ?? false,
        monthly_installment: data?.monthly_installment ?? "",
        financer_name: data?.financer_name ?? "",
        rented_amount: data?.rented_amount ?? 0,
        loan_duration: data?.loan_duration ?? 0,
        security_amount: data?.security_amount ?? 0,
        measurement_unit: data?.machinetypeData?.measurement_unit ?? "",
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [MachineInfoData]);

  if (result.isSuccess && result.data.status === 200) {
    navigate("/admin/machine");
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/admin/machine");
  }

  useEffect(() => {
    if (formData.is_rented) {
      setShowLoanCheckBox(!formData.is_rented);
    } else if (formData.is_loanable) {
      setShowRentedCheckBox(!formData.is_loanable);
    } else {
      setShowRentedCheckBox(true);
      setShowLoanCheckBox(true);
    }
  }, [formData.is_rented, formData.is_loanable]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const checkError = validate(formData);
    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      dispatch(setError([]));
      const purchaseDateTimestamp = Math.floor(
        new Date(formData.purchase_date).getTime()
      );
      let formattedData = {
        machine_type: formData?.machine_id._id ?? "",
        company: formData?.company ?? "",
        machine_model: formData?.machine_model ?? "",
        number_plate: formData?.number_plate.toUpperCase() ?? "",
        purchase_date: purchaseDateTimestamp,
        volume_capacity: Number(formData?.volume_capacity) || 0,
        tank_capacity: Number(formData?.tank_capacity) || 0,
        site_id: formData.site_id._id !== "" ? formData?.site_id?._id : null,
        isSiteChange: formData?.isSiteChange,
        is_rented: formData?.is_rented ?? false,
        is_loanable: formData?.is_loanable ?? false,
        monthly_installment: formData?.monthly_installment ?? "",
        financer_name: formData?.financer_name ?? "",
        loan_duration: formData?.loan_duration ?? 0,
        rented_amount: formData?.rented_amount ?? 0,
        security_amount: formData?.security_amount ?? 0,
        measurement_unit: formData?.measurement_unit ?? "",
        id: id ?? null,
        org_id: orgId ?? "",
      };

      id ? editMachineInfo(formattedData) : createMachineInfo(formattedData);
    }
  };
  const validate = (formData: UserState) => {
    const errors: Partial<UserState> = {};
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (!formData.machine_model.trim()) {
        errors.machine_model = "Machine Model is required!";
    }
    if (!formData.company.trim()) {
      errors.company = "Company is required!";
    }
    if (existingNumberPlates.includes(formData.number_plate)) {
      errors.number_plate = "Number Plate already exists!";
    } else if (!/\d/.test(formData.number_plate)) {
      errors.number_plate = "Number Plate must contain at least one number";
    }
    if (!formData.purchase_date) {
      errors.purchase_date = "Purchase date is required";
    }
    if (regex.test(formData.volume_capacity)) {
      errors.volume_capacity =
        "volume capacity cannot contain special characters!";
    }
    if (regex.test(formData.tank_capacity)) {
      errors.tank_capacity = "Tank Capacity cannot contain special characters!";
    }
    return errors;
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    let { name, value } = e.target;

    if(e.target.name === "number_plate"){
      value = value.toLocaleUpperCase();
    }
    if(e.target.name === "company" && value.length >0){
      value = value[0].toUpperCase()+value.slice(1);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]:
        name === "monthly_installment" ? Number(value) : value.trimStart(),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === "is_rented" && checked) {
      setFormData((prevState) => ({
        ...prevState,
        is_rented: true,
        is_loanable: false,
      }));
    } else if (name === "is_loanable" && checked) {
      setFormData((prevState) => ({
        ...prevState,
        is_rented: false,
        is_loanable: true,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const selectSitesChange = (event: any) => {
    const selectedSite = event.target.value;
    console.log("sddffsds",selectedSite);
    if (selectedSite === "no_selected") {
      setFormData({
        ...formData,
        site_id: { _id: "", name: "--No Option--" },
        isSiteChange: true
      });
      return;
    }
  
    let userData = MachineInfoData?.response?.siteData[0];
    if (userData?._id === selectedSite?._id) {
      setFormData({
        ...formData,
        site_id: selectedSite,
        isSiteChange: false,
      });
    } else {
      setFormData({
        ...formData,
        site_id: selectedSite,
        isSiteChange: true,
      });
    }
  };
  const handleSelectOpen = () => {
    refetch();
  };


  const selectMachineTypeChange = (event: any) => {
    const selectedValue = event.target.value.name;
    const machineDetails = machineTypeData?.data?.response.find(
      (machine: { name: any }) => machine.name === selectedValue
    );

    let userData = MachineInfoData?.response?.machinetypeData;
    if (userData?._id === event.target.value?._id) {
      setFormData({
        ...formData,
        machine_id: event.target.valueevent.target.value as string,
        isMachineChange: false,
        volume_capacity: "",
        number_plate: "",
        tank_capacity: "",
        measurement_unit: machineDetails?.measurement_unit || "",
      });
    } else {
      setFormData({
        ...formData,
        machine_id: event.target.value as string,
        isMachineChange: true,
        volume_capacity: "",
        number_plate: "",
        tank_capacity: "",
        measurement_unit: machineDetails?.measurement_unit || "",
      });
    }
  };

  return (
    <Container maxWidth={false} className="p-0">
      <Card elevation={3} className="form-outers">
        <CardHeader
          className="inner-headings"
          title={id ? "Edit Machinery" : "Add Machinery"}
        />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Machine Types*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData?.machine_id}
                      label="Machine Types"
                      required
                      onChange={selectMachineTypeChange}
                      renderValue={() => formData.machine_id?.name}
                    >
                      {machineTypeData?.data?.response.map(
                        (item: any, index: number) => (
                          <MenuItem value={item} key={item}>
                            {item?.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
             
             <Grid item xs={12} md={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Sites Names
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData?.site_id}
                      label="Sites Name"
                      onChange={selectSitesChange}
                      onOpen={handleSelectOpen}
                      renderValue={() => formData?.site_id?._id ? formData?.site_id?.name : "--No Option--"}
                    >
                      <MenuItem value="no_selected">
                        --No Option--
                      </MenuItem>

                      {SitesData?.data?.response?.map(
                        (item: any, index: number) => (
                          <MenuItem value={item} key={item?._id}>
                            {item?.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label="Company"
                  name="company"
                  type="text"
                  size="small"
                  autoComplete="company"
                  value={formData.company}
                  onChange={handleChange}
                  error={!!formErrors.company}
                  helperText={formErrors.company}
                />
                <Error current_key="company" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="machine_model"
                  label="Machine Model"
                  name="machine_model"
                  type="text"
                  size="small"
                  autoComplete="machine_model"
                  value={formData.machine_model}
                  onChange={handleChange}
                  error={!!formErrors.machine_model}
                  helperText={formErrors.machine_model}
                />
                <Error current_key="machine_model" />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  id="purchase_date"
                  label="Purchase Date"
                  name="purchase_date"
                  className="calendradate"
                  type="date"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="purchase_date"
                  value={formData.purchase_date}
                  onChange={handleChange}
                  error={!!formErrors.purchase_date}
                  helperText={formErrors.purchase_date}
                />
                <Error current_key="purchase_date" />
              </Grid>
              <Grid item xs={12} md={6}>
                {showRentedCheckBox && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_rented}
                        onChange={handleCheckboxChange}
                        name="is_rented"
                      />
                    }
                    label="Rented Machine"
                  />
                )}
                {showLoanCheckBox && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.is_loanable}
                        onChange={handleCheckboxChange}
                        name="is_loanable"
                      />
                    }
                    label="Loanable"
                  />
                )}
              </Grid>
              {formData?.is_rented && (
                <div className="isRented">
                  <Grid container spacing={3}>
                    {formData?.is_rented && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          id="rented_amount"
                          label="Rented Amount"
                          name="rented_amount"
                          type="number"
                          size="small"
                          inputProps={{
                            min: 1,
                          }}
                          autoComplete="rented_amount"
                          value={formData.rented_amount}
                          onChange={handleChange}
                        />
                        <Error current_key="rented_amount" />
                      </Grid>
                    )}
                    {formData?.is_rented && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          id="security_amount"
                          label="Security Amount"
                          name="security_amount"
                          type="number"
                          size="small"
                          inputProps={{
                            min: 1,
                          }}
                          autoComplete="security_amount"
                          value={formData.security_amount}
                          onChange={handleChange}
                        />
                        <Error current_key="security_amount" />
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
              {formData?.is_loanable && (
                <div className="isLoanable">
                  <Grid container spacing={3}>
                    {formData?.is_loanable && (
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          id="financer_name"
                          label="Financer Name"
                          name="financer_name"
                          type="text"
                          size="small"
                          inputProps={{
                            min: 1,
                          }}
                          autoComplete="financer_name"
                          value={formData.financer_name}
                          onChange={handleChange}
                        />
                        <Error current_key="financer_name" />
                      </Grid>
                    )}
                    {formData?.is_loanable && (
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          id="loan_duration"
                          label="Loan Duration (In Months)"
                          name="loan_duration"
                          type="number"
                          size="small"
                          inputProps={{
                            min: 1,
                          }}
                          autoComplete="loan_duration"
                          value={formData.loan_duration}
                          onChange={handleChange}
                        />
                        <Error current_key="loan_duration" />
                      </Grid>
                    )}
                    {formData?.is_loanable && (
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          id="monthly_installment"
                          label="Montly Installment"
                          name="monthly_installment"
                          type="number"
                          size="small"
                          inputProps={{
                            min: 1,
                          }}
                          autoComplete="monthly_installment"
                          value={formData.monthly_installment}
                          onChange={handleChange}
                        />
                        <Error current_key="monthly_installment" />
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
              <ReusableAddEditMachineInfo
                machineType={formData.machine_id}
                formData={formData}
                handleChange={handleChange}
                formErrors={formErrors}
              />

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="save-btn"
                  onSubmit={handleSubmit}
                  style={{ marginRight: "10px" }}
                  disabled={Object.values(formErrors).some((error) => !!error)}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="close-btn"
                  onClick={() => navigate("/admin/machine")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddEditMachineInfo;
