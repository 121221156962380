import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  memo,
  useEffect,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Error from "../../components/ErrorBox/Error";
import {
  setError,
  useCreateFuelTypeMutation,
  useEditFuelTypeMutation,
  useGetFuelTypeByIdQuery,
} from "../../redux-services";
import { useDispatch, useSelector } from "react-redux";
import { FuelUnitTypes, FuelTypes } from "../../ constants";
import { RootState } from "../../store/store";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
};
export interface FormFields {
  fuel_type: { id: number; title: string };
  fuel_unit: { id: number; title: string };
  company_name: string;
}

const AddEditFuelType: FC<{
  open: boolean;
  handleModel: any;
  fuelTypeId?: string;
}> = ({ open, handleModel, fuelTypeId }) => {
  let dispatch = useDispatch();
  const intialState: FormFields = {
    fuel_type: { id: 1, title: "" },
    fuel_unit: { id: 1, title: "" },
    company_name: "",
  };
  const [formData, setFormData] = useState<FormFields>(intialState);
  const { data: getStorageDataById } = useGetFuelTypeByIdQuery(fuelTypeId, {
    skip: !fuelTypeId,
  });
  const [createFuelType, createResult] = useCreateFuelTypeMutation();
  const [editFuelType, editResult] = useEditFuelTypeMutation();
  const userDetail = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    return () => {
      setFormData(intialState);
    };
  }, []);

  useEffect(() => {
    if (getStorageDataById?.status === 200) {
      const FuelType = FuelTypes?.find(
        (item: { id: number; title: string }) =>
          item.id === getStorageDataById?.response.fuel_type
      );
      const FuelUnit = FuelUnitTypes?.find(
        (item: { id: number; title: string }) =>
          item.id === getStorageDataById?.response.fuel_unit
      );

      setFormData({
        ...formData,
        fuel_type: FuelType ?? { id: 1, title: "Diesel" },
        fuel_unit: FuelUnit ?? { id: 1, title: "Ltr." },
        company_name: getStorageDataById?.response.company_name ?? "",
      });
    }
  }, [getStorageDataById]);

  const handleChange = (e: ChangeEvent<any>) => {
    let { name, value } = e.target;

    if (e.target.name === "company_name" && value.length >0) {
      value = value[0].toUpperCase() + value.slice(1);
    }
    
    setFormData({ ...formData, [name]: value });
  };

  const handleFuelUnit = (data: any) => {
    setFormData({ ...formData, fuel_unit: data });
  };

  const handleFuelType = (data: any) => {
    setFormData({ ...formData, fuel_type: data });
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    e.preventDefault();
    dispatch(setError([]));

    let formattedData = {
      fuel_type: formData.fuel_type?.id ?? 0,
      fuel_unit: formData.fuel_unit?.id,
      company_name: formData.company_name.trim(),
      id: fuelTypeId ?? null,
    };
    fuelTypeId ? editFuelType(formattedData) : createFuelType(formattedData);
  };

  if (
    (editResult.isSuccess && editResult.data.status === 200) ||
    (createResult.isSuccess && createResult.data.status === 200)
  ) {
    handleModel(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="form-outers karuModal">
          <Typography
            id="transition-modal-title"
            variant="h5"
            className="inner-headings"
            component="h2"
          >
            {fuelTypeId ? "Edit" : "Add"} Fuel Type
          </Typography>
          <HighlightOffIcon
            className="closeicon"
            onClick={() => handleModel(false)}
          />
          <Box className="modalBody">
            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Autocomplete
                    id="select-fuel"
                    options={FuelTypes ?? []}
                    size="small"
                    onChange={(
                      event,
                      newValue: { id: number; title: string } | null
                    ) => {
                      handleFuelType(newValue);
                    }}
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#0a243d" }}>
                        {children}
                      </Paper>
                    )}
                    value={formData.fuel_type}
                    autoHighlight
                    getOptionLabel={(option: any) => option?.title}
                    renderOption={(props, option: any) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.title ?? ""}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Fuel Type"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="select-fuel"
                    options={FuelUnitTypes ?? []}
                    size="small"
                    onChange={(
                      event,
                      newValue: { id: number; title: string } | null
                    ) => {
                      handleFuelUnit(newValue);
                    }}
                    PaperComponent={({ children }) => (
                      <Paper style={{ background: "#0a243d" }}>
                        {children}
                      </Paper>
                    )}
                    value={formData.fuel_unit}
                    autoHighlight
                    getOptionLabel={(option: any) => option?.title}
                    renderOption={(props, option: any) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.title ?? ""}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Fuel Unit"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    id="company_name"
                    label="Company Name"
                    name="company_name"
                    type="text"
                    size="small"
                    autoComplete="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                  />
                  <Error current_key={formData.company_name} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    style={{ marginRight: "10px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="close-btn"
                    onClick={() => handleModel(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default memo(AddEditFuelType);
